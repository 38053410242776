import React from 'react';
import './index.scss';

import {Animate} from 'react-move'
import {connect} from 'react-redux'
import BezierEasing from 'bezier-easing'

const DoTheScroll = (props) => {
	const scrollWrapper = document.getElementsByClassName('scroll-wrapper')[0];
    if (scrollWrapper && props.scrollIsAnimating) scrollWrapper.scrollTop = props.scrollPos;
	return null;
}

function AnimatedScrollHandler(props) {
	var bezierCurve = BezierEasing(0.4,0,0.1,1);

  return (
      	<Animate
	    	start={{scrollPos: 0}}
		    update={{
		      scrollPos: [props.scrollDest],
		      timing: {duration: props.scrollIsAnimating ? 1000 : 0, ease: bezierCurve}
		    }}
		>
		    {({scrollPos}) => (
				<DoTheScroll scrollPos={scrollPos} scrollIsAnimating={props.scrollIsAnimating}/>
		    )}
	  </Animate>
  );
}

export default connect((state) => ({
	scrollDest: state.App.scrollDest,
	scrollIsAnimating: state.App.scrollIsAnimating
}))(AnimatedScrollHandler);
