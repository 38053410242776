const initialState = {
	sidebarIsOpen: false,
	filterIsOpen: false,
	filterIsAnimating: false,
	achiveLayoutIsWide: true
}


const Sidebar = (state = initialState, action) => {
	switch(action.type) {
		case 'TOGGLE_SIDEBAR':
			return {
				...state,
				sidebarIsOpen: !state.sidebarIsOpen
			}
		break;

		case 'CLOSE_SIDEBAR':
			return {
				...state,
				sidebarIsOpen: false
			}
		break;

		case 'TOGGLE_FILTER':
			return {
				...state,
				filterIsOpen: !state.filterIsOpen
			}
		break;

		case 'CLOSE_FILTER':
			return {
				...state,
				filterIsOpen: false,
				achiveLayoutIsWide: true
			}
		break;

		case 'FILTER_IS_ANIMATING':
			return {
				...state,
				filterIsAnimating: action.val
			}
		break;

		case 'SET_ARCHIVE_LAYOUT_TO_WIDE':
			return {
				...state,
				achiveLayoutIsWide: action.val
			}

		default:
			return state
	}
}

export default Sidebar