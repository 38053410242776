import React, {useEffect} from 'react';
import './index.scss';
import {Animate} from 'react-move'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'

import {generateLink, convertHTMLStringToReact} from './../../helpers/helpers'
import LinkWrapper from './../LinkWrapper'
import {ToggleFilter} from './../../actions'

function Filter(props) {
  return (
    <div>
      <LinkWrapper><div className={`toggle-sidebar-btn toggle-filter-btn${props.filterIsOpen ? ' open' : ''}`} style={{transform: `translateZ(0) translateY(0px)`}} onClick={() => ToggleFilter()}><div className="inner"><div className="line first"/><div className="line second"/><div className="line third"/></div></div></LinkWrapper>

      <div className={`sidebar archivefilter ${props.filterIsOpen ? 'open' : 'closed'}`}>
          <div className="inner">
              {props.apiData.taxonomies.map((tax, key) => {
                let taxIsActive = props.selectedTerms ? props.selectedTerms[tax.displayname] ? true : false : false
                let selectedTaxtermsAsArray = taxIsActive ? props.selectedTerms[tax.displayname].split(',') : [];

                return(
                  <div key={key} className={`filter-col ${tax.displayname} ${taxIsActive ? 'active' : ''}`}>
                    <div className="title">{tax.displayname}</div>
                    <ul>
                       {tax.terms.map((term, termKey) => {
                        let termIsSelected = selectedTaxtermsAsArray.includes(term.slug);

                        return(
                          <li key={termKey} className={`${termIsSelected ? 'active' : ''}`}><LinkWrapper><Link to={`/archive?${generateLink(tax.displayname, term.slug, termIsSelected, props.selectedTerms, props.selectedTermsAsArray)}`}>{convertHTMLStringToReact(term.name)}</Link></LinkWrapper></li>
                        )})}
                    </ul>
                      
                  </div>
                )})}
          </div>
      </div>
    </div>
  );
}

export default connect((state, ownProps) => ({
  apiData: state.App.pageData[ownProps.dataKey].data,
	filterIsOpen: state.Sidebar.filterIsOpen,
  selectedTerms: state.Archive.selectedTerms,
  selectedTermsAsArray: state.Archive.selectedTermsAsArray,
}))(Filter);
