import React, {useRef, useEffect, useState} from 'react';
import './index.scss';

import {connect} from 'react-redux'

import ScrollVisibilityComponent from './../ScrollVisibilityComponent'


function Slide(props) {
	const [pos, setPos] = useState(0);

	useEffect(() => {
		setPos(props.id*props.width)
	}, [props.width])

	useEffect(() => {
		const speed = 2;
		setPos(prevPos => prevPos - speed < props.width * -1 ? (props.numOfSlides - 1) * props.width : prevPos - speed)
	}, [props.offset])

	return(
		<div className="slider-item" style={{width: props.width, transform: `translateX(${pos}px)`}}><img style={{transform: `scale(${props.slideSize})`}}src={props.data}/></div>
		)
}

function Clients(props) {
	let slideWidth = props.deviceWidth > 1500 ? 450 :
	props.deviceWidth > 800 ? 300 : 150;

	let slideSize = props.deviceWidth > 2000 ? 1.4 :
	props.deviceWidth > 1500 ? 1.2 :
	props.deviceWidth > 800 ? 1 : 0.5;

	const requestRef = useRef();
	const previousTimeRef = useRef();
	const [offset, setOffset] = useState(0);
	const [deltaTime, setDeltaTime] = useState(0);

	const animate = (time) => {
		if (previousTimeRef.current != undefined) {
			const deltaTime = time - previousTimeRef.current;
			setOffset(prevCount => (prevCount + deltaTime * 0.1))
		}
		previousTimeRef.current = time;
		requestRef.current = requestAnimationFrame(animate)
	}

	useEffect(() => {
		requestRef.current = requestAnimationFrame(animate);
		return () => cancelAnimationFrame(requestRef.current)
	}, [])


  return (
    <ScrollVisibilityComponent>
		<div className="page-section border-top client-carousel">
			<h3 className="section-title">A few of our collaborators</h3>
	    	<div className="slider-track">
		  		{props.data.map((data, key) => (
		  			<Slide key={key} id={key} offset={offset} width={slideWidth} numOfSlides={props.data.length} data={data} slideSize={slideSize}/>
				))}
	    	</div>
		</div>
    </ScrollVisibilityComponent>
  );
}

export default connect((state) => ({
	deviceWidth: state.Device.size.width
}))(Clients);
