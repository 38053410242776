import React, {useEffect, useRef, useState} from 'react';
import {Link} from 'react-router-dom'
import {connect} from 'react-redux'

import {getApiBase} from './../../helpers/helpers.js';

import './index.scss';

import Footer from './../Footer'
import {ImageLarge, ImageFullscreen, Text, TwoImages, Video, ImageAndText} from './projectSections.js'
import {RelatedProjects} from './relatedProjects.js'
import {ToggleSidebar, CloseSidebar} from './../../actions'

function ProjectSingle(props) {
    const [transformAmount, setTransformAmount] = useState(0); 
    const [marginBottomAmount, setMarginBottomAmount] = useState(0); 
    const relatedProjectsRef = useRef();
    const projectTitleRef = useRef();
    const projectDataRef = useRef();

    // close sidebar if entering by link, keep filter state if entering by browser history
    useEffect(() => {
        if (props.routerAction == 'PUSH') CloseSidebar();

        var val = projectDataRef.current.offsetHeight + 80 - (projectDataRef.current.offsetHeight - projectTitleRef.current.offsetHeight);
        setMarginBottomAmount(val);

        var val = projectTitleRef.current.offsetHeight + 80;
        setTransformAmount(val);
    }, [props.size])

    // show fixed project title on scroll
    useEffect(() => {
        const scrollTriggerOffset = 400;
        if (props.scrollPosition >= scrollTriggerOffset && props.currentSingleName.length == 0) props.dispatch({type: 'SHOW_SINGLE_NAME'});
        else if (props.scrollPosition < scrollTriggerOffset && props.currentSingleName.length > 0) props.dispatch({type: 'HIDE_SINGLE_NAME'})
    }, [props.scrollPosition])

    useEffect(() => {
        if (relatedProjectsRef.current) {
            let offset = relatedProjectsRef.current.offsetTop
            let headlineHeight = projectTitleRef.current.offsetHeight;
        }
    }, [props.sidebarIsOpen])

  return (
    <div className={`page project-single${props.sidebarIsOpen ? ' open' : ''}`}>
    	<div className="page-section headline" style={{marginBottom: props.sidebarIsOpen && !props.isMobileSize ? marginBottomAmount : 0}}>
            <div className="col col8 title col16-xl">
                <div className="inner">
                    <h1 ref={projectTitleRef} dangerouslySetInnerHTML={{__html: props.apiData.alternative_title ? props.apiData.alternative_title : props.apiData.title}}/>
                </div>
            </div>
            <div ref={projectDataRef} className="col col8 data col16-xl col16-med colmar0l-med colmar0r-med" style={{transform: `translate(${props.sidebarIsOpen && !props.isMobileSize ? `-100%, ${transformAmount}px` : '0,0'})`}}>
                <div className="inner">
                    <div className="projectdata">
                    {props.apiData.data ? props.apiData.data.map((ele, key) => (
                            <div className="row" key={key}>
                                <div className="title">{ele.title}</div>
                                <div className="description">{ele.name}</div>
                            </div>
                        )) : null}
                    </div>
                </div>
            </div>
        </div>

        {props.apiData.main_sections ? props.apiData.main_sections.map((singleData, key) => (
            singleData.acf_fc_layout === 'image_fullscreen' ? <ImageFullscreen key={key} data={singleData}/> : 
            singleData.acf_fc_layout === 'image_large' ? <ImageLarge key={key} data={singleData}/> : 
            singleData.acf_fc_layout === 'two_images' ? <TwoImages key={key} data={singleData}/> : 
            singleData.acf_fc_layout === 'text' ? <Text key={key} data={singleData}/> :
            singleData.acf_fc_layout === 'video' ? <Video key={key} data={singleData} vid={'v'+key}/> :
            singleData.acf_fc_layout === 'text_and_image' ? <ImageAndText key={key} data={singleData}/> : null

            )) : null}
        
        <div ref={relatedProjectsRef}>
            <RelatedProjects data={props.apiData.related_projects}/>
        </div>

        <Footer/>

    </div>
  );
}

export default connect((state, ownProps) => ({
    apiData: state.App.pageData[ownProps.dataKey].data,
    scrollPosition: state.App.scrollPosition,
    sidebarIsOpen: state.Sidebar.sidebarIsOpen,
    currentSingleName: state.App.currentSingleName,
    routerAction: state.router.action,
    isMobileSize: state.Device.isMobileSize,
    size: state.Device.size
}))(ProjectSingle)
