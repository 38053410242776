import React from 'react';
import {connect} from 'react-redux'

import './index.scss';
import Footer from './../Footer'
import LazyImageComponent from './../LazyImageComponent'
import ScrollVisibilityComponent from './../ScrollVisibilityComponent'
import BottomPageLink from './../BottomPageLink'
import Clients from './../Clients'

import {animateScroll} from './../../actions';
import {Template1, Template2, Template3, Template4} from './StudioSections'

import {convertHTMLStringToReact} from './../../helpers/helpers.js'

function Studio(props) {
    const linkClickHandler = (e) => {
        props.dispatch({type: 'SET_CURSOR_ACTIVE', active: false})
        const targetLink = e.target.closest('a');
        if (!targetLink) return;
        e.preventDefault();
        const targetId = targetLink.getAttribute("href").substring(1,99);
        let ele = document.getElementById(targetId).parentElement;
        let offsetTop = ele.offsetTop;
        let scrollWrapper = document.getElementsByClassName('scroll-wrapper')[0];
        animateScroll(offsetTop + 24);
    }

  return (
    <div className="page studio normal">        
    	<div className="page-section headline">
            <div className="col col16">
    		  <div className="headertext" onClick={linkClickHandler}>{convertHTMLStringToReact(props.apiData.acf.headertext)}</div>
            </div>
    	</div>

        <ScrollVisibilityComponent>
        	<div className="page-section full">
                <LazyImageComponent width={props.apiData.acf.fullscreen_headerimage.sizes[`fullscreen-width`]} height={props.apiData.acf.fullscreen_headerimage.sizes[`fullscreen-height`]} url={props.apiData.acf.fullscreen_headerimage.sizes.fullscreen}/>
        	</div>
        </ScrollVisibilityComponent>


    	{props.apiData.acf.sections.map((singleData, key) => (
                singleData.acf_fc_layout === 'template_1' ? <Template1 key={key} data={singleData} firstSection={key === 0}/> :
                singleData.acf_fc_layout === 'template_2' ? <Template2 key={key} data={singleData} firstSection={key === 0}/> :
                singleData.acf_fc_layout === 'template_3' ? <Template3 key={key} data={singleData} firstSection={key === 0}/> :
                singleData.acf_fc_layout === 'template_4' ? <Template4 key={key} data={singleData} firstSection={key === 0}/> : null
            ))}

        <BottomPageLink data={props.bottomPageLink}/>
        <Clients data={props.apiData.acf.clients}/>
        <Footer/>

    </div>
  );
}

export default connect((state, ownProps) => ({
    apiData: state.App.pageData[ownProps.dataKey].data[0],
    bottomPageLink: {
        title: state.App.pageData[ownProps.dataKey].data[0].acf.bottom_pagelink_title,
        image1: state.App.pageData[ownProps.dataKey].data[0].acf.bottom_pagelink_image1,
        image2: state.App.pageData[ownProps.dataKey].data[0].acf.bottom_pagelink_image2,
        text: state.App.pageData[ownProps.dataKey].data[0].acf.bottom_pagelink_text,
        linkObj: state.App.pageData[ownProps.dataKey].data[0].acf.bottom_pagelink_link,
        linktext: state.App.pageData[ownProps.dataKey].data[0].acf.bottom_pagelink_linktext
    }
}))(Studio)
