import React from 'react';
import {connect} from 'react-redux'

import './index.scss';
import Footer from './../Footer'
import BottomPageLink from './../BottomPageLink'
import LinkWrapper from './../LinkWrapper'
import {convertHTMLStringToReact} from './../../helpers/helpers.js'

import {Template1, Template2, Template3, Template4} from './ContactSections'

function Contact(props) {
  return (
    <div className="page contact normal">
        <div className="page-section headline border-bottom">
            <div className="col col16">
                <h1>{props.apiData.acf.headertext}</h1>
                <LinkWrapper><a href={`${props.apiData.acf.google_maps_link}`} className="gmaps_link" target="_blank">Google Maps</a></LinkWrapper>
            </div>
        </div>

        {props.apiData.acf.sections.map((singleData, key) => (
                singleData.acf_fc_layout === 'template_1' ? <Template1 key={key} data={singleData} firstSection={key === 0}/> :
                singleData.acf_fc_layout === 'template_2' ? <Template2 key={key} data={singleData} firstSection={key === 0}/> :
                singleData.acf_fc_layout === 'template_3' ? <Template3 key={key} data={singleData} firstSection={key === 0}/> :
                singleData.acf_fc_layout === 'template_4' ? <Template4 key={key} data={singleData} firstSection={key === 0}/> : null
            ))}
        
        <BottomPageLink data={props.bottomPageLink}/>
        <Footer/>

    </div>
  );
}

export default connect((state, ownProps) => ({
    apiData: state.App.pageData[ownProps.dataKey].data[0],
    bottomPageLink: {
        title: state.App.pageData[ownProps.dataKey].data[0].acf.bottom_pagelink_title,
        image1: state.App.pageData[ownProps.dataKey].data[0].acf.bottom_pagelink_image1,
        image2: state.App.pageData[ownProps.dataKey].data[0].acf.bottom_pagelink_image2,
        text: state.App.pageData[ownProps.dataKey].data[0].acf.bottom_pagelink_text,
        linkObj: state.App.pageData[ownProps.dataKey].data[0].acf.bottom_pagelink_link,
        linktext: state.App.pageData[ownProps.dataKey].data[0].acf.bottom_pagelink_linktext
    }
}))(Contact)