import React, {useState} from 'react';
import {NavLink} from 'react-router-dom'
import {connect} from 'react-redux'
import {ReactComponent as MenuDots} from './../../../assets/icons/menudots.svg'
import {isMobile} from './../../helpers/helpers.js'
import {ReactComponent as ArrowLink} from './../../../assets/icons/arrowLink.svg'

import './index.scss';

import LinkWrapper from './../LinkWrapper'

function NavigationMain(props) {
	const [menuIsShowing, setMenuIsShowing] = useState(false)

const clickingMenu = () => {
	console.log('cliking menu');
}

  return (
  		<div className={`navigation-wrapper ${props.menuIsShowing ? 'open' : ''} ${props.isMobile ? 'mobile' : 'desktop'}`}>
  			<div className="navigationmain">
		    	<ul>
		    		<li>
			    		<LinkWrapper><NavLink to="/projects" onClick={() => clickingMenu} ><ArrowLink/><span>projects</span></NavLink></LinkWrapper>
			    	</li>
			    	<li>
			    		<LinkWrapper><NavLink to="/people" onClick={() => clickingMenu} ><ArrowLink/><span>people</span></NavLink></LinkWrapper>
			    	</li>
			    	<li>
			    		<LinkWrapper><NavLink to="/studio" onClick={() => clickingMenu} ><ArrowLink/><span>studio</span></NavLink></LinkWrapper>
			    	</li>
			    	<li>
			    		<LinkWrapper><NavLink to="/contact" onClick={() => clickingMenu} ><ArrowLink/><span>contact</span></NavLink></LinkWrapper>
			    	</li>
			    </ul>
			</div>
		    <LinkWrapper>
    			<MenuDots
    				onClick={() => props.dispatch({type: 'TOGGLE_MENU_IS_SHOWING'})}
    			/>
		    </LinkWrapper>
		 </div>
  );
}

export default connect(state => ({
	menuIsShowing: state.App.menuIsShowing,
	isMobile: state.Device.isMobile
	}))(NavigationMain);