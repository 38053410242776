import React, {useEffect} from 'react';
import {Switch, Route, Link, useRouteMatch} from 'react-router-dom'
import {connect} from 'react-redux';
import {Animate} from 'react-move'
import _ from 'lodash'
import BezierEasing from 'bezier-easing'
import LinkWrapper from './../LinkWrapper'

import {moveBrandlogoBack} from './../../actions'

import './index.scss';


function PeopleSingle(props) {
	let match = useRouteMatch();
  	var easingBezier = BezierEasing(0.9, 0, 0.1, 1);


	useEffect(() => {
		props.dispatch({
			type: 'SET_SCROLL_ON_OFF',
			val: match ? false : true
		})

		moveBrandlogoBack(match ? true : false);

	}, [match])

	return(
		 <Animate
		 	show={match ? true : false}
            start={{backgroundOpacity: 0, xPos: 100, btnOpacity: 0}}
            enter={[{
            		backgroundOpacity: props.isMobileSize ? [0] : [1],
            		timing: {duration: 300, delay: 0}
            	},{
            		xPos: [0],
            		timing: {delay: 50, duration: 600, ease: easingBezier}
            	}, {
            		btnOpacity: [1],
            		timing: {duration: 300, delay: 500}
            	}]}
            leave={[{
            		backgroundOpacity: [0],
            		timing: {delay: 400, duration: 300}
            	},{
            		xPos: [100],
            		timing: {duration: 600, delay: 0, ease: easingBezier}
            	},{
            		btnOpacity: [0],
            		timing: {duration: 300, delay: 0}
            	}]}
        >
	        {({backgroundOpacity, xPos, btnOpacity}) => {
	            return(
	                <div className="peoplesingle" style={{top: `${props.scrollPosition}px`, background: `rgba(255,255,255,${backgroundOpacity})`}}>
						<LinkWrapper><Link to="/people"><div className="close-btn" style={{top: props.singlePersonThumbOffsetsetTop, opacity: btnOpacity}}><div className="inner"/></div></Link></LinkWrapper>
						<div className="sidebar" style={{transform: `translateX(${xPos}%) translateZ(0)`, paddingTop: props.isMobileSize ? props.singlePersonThumbOffsetsetTop : props.singlePersonThumbOffsetsetTop + 70}}>
							<div className="inner">
								<h1 className="name">{props.personData.title}</h1>
								<ul className="data">
									<li>{props.personData.profession}</li>
									<li>{props.personData.work_title}</li>
								</ul>
								<ul className="contact">
									{props.personData.phone ? <li><LinkWrapper><a href={`tel:${props.personData.phone}`}>{props.personData.phone}</a></LinkWrapper></li> : null}
									{props.personData.email ? <li><LinkWrapper><a href={`mailto:${props.personData.email}`}>{props.personData.email}</a></LinkWrapper></li> : null}
								</ul>
								<div className="description" dangerouslySetInnerHTML={{__html: props.personData.description}}/>
							</div>
						</div>
					</div>
	                )
	        }}
        </Animate>
		)
}

export default connect((state, ownProps) => {
	const dataPeopleAll = state.App.pageData[ownProps.dataKey].data.people
	const dataPeopleCurrent = _.find(dataPeopleAll, (o) => {return o.slug === state.App.singlePersonSlug})

	return ({
		scrollPosition: state.App.scrollPosition,
		personData: dataPeopleCurrent,
		singlePersonThumbOffsetsetTop: state.App.singlePersonThumbOffsetsetTop,
		isMobileSize: state.Device.isMobileSize
	})})(PeopleSingle)