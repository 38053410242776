import React from 'react';
import './index.scss';

import ScrollVisibilityComponent from './../ScrollVisibilityComponent'

function Footer() {
  return (
	    <div className="page-section footer border-top">
	        <div className="col col16">
	            © RODEO ARCHITECTS {new Date().getFullYear()}
	        </div>
	    </div>
  );
}

export default Footer;
