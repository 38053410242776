import React from 'react';
import LazyImageComponent from './../LazyImageComponent'
import ScrollVisibilityComponent from './../ScrollVisibilityComponent'
import LinkWrapper from './../LinkWrapper'
import {Link} from 'react-router-dom'
import {convertHTMLStringToReact} from './../../helpers/helpers.js'

const ImageWithLink = (link, width, height, url) => {
    return (
        link ? 
            <ScrollVisibilityComponent>
                <LinkWrapper>
                    <Link to={`/projects/${link}`}>
                        <LazyImageComponent width={width} height={height} url={url}/>
                    </Link>
                </LinkWrapper>
            </ScrollVisibilityComponent>
            : 
            <ScrollVisibilityComponent>
                <LazyImageComponent width={width} height={height} url={url}/>
            </ScrollVisibilityComponent>
        )
}

export const Template1 = ({data, firstSection}) => {
	return(
        <ScrollVisibilityComponent>
    		<div className={`page-section contact template1 ${!firstSection ? 'border-top' : ''}`}>
                <div className="row">
        		    <h3 className="section-title">{data.title}</h3>
                    <div className="col col8 colmar8l col16-s colmar0l-s">
                        <div className="inner">
                            {/*<div className="text" dangerouslySetInnerHTML={{__html: data.text}}/>*/}
                            {<div className="text">{convertHTMLStringToReact(data.text)}</div>}
                        </div>
                    </div>
        		</div>
                <div className="row row-fl-r-s">
            		<div className="col col7 colmar1r col16-s colmar0r-s">
                        <div className="inner">
                            {ImageWithLink(data.image_left_link.post_name, data.image_left.width, data.image_left.height, data.image_left.sizes.halfscreen)}
                        </div>
                    </div>
                    <div className="col col4 colmar4l bottom col8-s colmar0l-s hide-s">
                        <div className="inner">
                            {ImageWithLink(data.image_right_link.post_name, data.image_right.width, data.image_right.height, data.image_right.sizes.halfscreen)}
                        </div>
                    </div>
                </div>
        	</div>
        </ScrollVisibilityComponent>
		)
}

export const Template2 = ({data, firstSection}) => {
	return(
        <ScrollVisibilityComponent>
    		<div className={`page-section contact template2 ${!firstSection ? 'border-top' : ''}`}>
    			<h3 className="section-title">{data.title}</h3>
        		<div className="col col4 colmar4l colmar0l-s col16-s">
        			<div className="inner">
                        <div className="text">{convertHTMLStringToReact(data.column_1)}</div>
        			</div>
        		</div>
        		<div className="col col4 col16-s">
        			<div className="inner"> 
                        <div className="text">{convertHTMLStringToReact(data.column_2)}</div>
        			</div>
        		</div>
        		<div className="col col4 col16-s">
        			<div className="inner"> 
                        <div className="text">{convertHTMLStringToReact(data.column_3)}</div>
        			</div>
        		</div>
        	</div>
        </ScrollVisibilityComponent>
		)
}

export const Template3 = ({data, firstSection}) => {
	return(
        <ScrollVisibilityComponent>
    		<div className={`page-section contact template3 ${!firstSection ? 'border-top' : ''}`}>
    			<h3 className="section-title">{data.title}</h3>
        		<div className="col col12 colmar4l col16-s colmar0l-s">
        			<div className="inner"> 
                        <div className="text">{convertHTMLStringToReact(data.text)}</div>
        			</div>
        		</div>
        	</div>
        </ScrollVisibilityComponent>
		)
}

export const Template4 = ({data, firstSection}) => {
    return(
        <ScrollVisibilityComponent>
            <div className={`page-section contact template4 ${!firstSection ? 'border-top' : ''}`}>
                <div className="row">
                    <h3 className="section-title">{data.title}</h3>
                    <div className="col col8 colmar8l col16-s colmar0l-s">
                        <div className="inner">
                            {<div className="text">{convertHTMLStringToReact(data.text)}</div>}
                        </div>
                    </div>
                </div>
            </div>
        </ScrollVisibilityComponent>
        )
}