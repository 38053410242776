import React, {useState} from 'react';
import VisibilitySensor from 'react-visibility-sensor'
import {playPauseVideo} from './../../helpers/helpers.js'

import './index.scss';

function ScrollVisibilityComponent({children, callback, vidId}) {
	const [isVisible, setIsVisible] = useState(false);
	const [hasBeenInViewport, setHasBeenInViewport] = useState(false);

  return (
  	<VisibilitySensor partialVisibility={true} /*active={hasBeenInViewport ? false : true}*/ onChange={(isVisible) => {if (!hasBeenInViewport) setIsVisible(isVisible); if (isVisible) setHasBeenInViewport(true); if (callback == 'video') playPauseVideo(isVisible, vidId)}}>
  		<div className={`viewport-visibility-component ${isVisible ? 'visible' : 'hidden'}`}>
    		{children}
    	</div>
    </VisibilitySensor>	
  );
}

export default ScrollVisibilityComponent;
