const initialState = {
	pageIsLoading: false,
	pageData: null,
	pagesToShow: [],
	singlePersonSlug: null,
	scrollPosition: 0,
	scrollDest: null,
	scrollIsAnimating: false,
	menuIsShowing: false,
	currentMenuName: [],
	scrollIsActive: true,
	singlePersonThumbOffsetsetTop: null,
	currentSingleName: [],
}

const removeAllButCurrentPage = (pages, key) => {
	return pages.filter((item) => item.key === key)
}

const removeAllButCurrentPagedata = (data, key) => {
	return data.filter((item) => item.key === key)
}

const App = (state = initialState, action) => {
	switch(action.type) {

		case 'PAGE_HAS_LOADED':
			// let currentPageKey = Math.random().toString(36).substr(2,9)
			const currentPageKey = action.pageObj.url;

			return {
				...state,
				pageIsLoading: false,
				pagesToShow: [{key: currentPageKey, component: action.pageObj.component}],
				pageData: {
					...state.pageData,
					[currentPageKey]: {
						component: action.pageObj.component,
						menuName: action.pageObj.menuname,
						data: action.pageObj.data
					}
				},
				menuIsShowing: false,
				currentMenuName: [action.pageObj.menuname],
				scrollPosition: 0,
				currentSingleName: []
			}
		break;

		case 'SET_CURRENT_PAGE':
			const component = state.pageData[action.pageKey].component;
			const menuName = state.pageData[action.pageKey].menuName;

			return {
				...state,
				pagesToShow: [{key: action.pageKey, component: component}],
				pageIsLoading: false,
				menuIsShowing: false,
				currentMenuName: [menuName],
				scrollPosition: 0,
				currentSingleName: []
			}
		break;

		case 'PAGE_TRANSITION_COMPLETE':
			const keyOfCurrentPage = state.pagesToShow[0].key;

			return {
				...state,
				pageData: {[keyOfCurrentPage]: state.pageData[keyOfCurrentPage]}
			}
		break;

		case 'PAGE_IS_LOADING':
			return {
				...state,
				pageIsLoading: true,
				menuIsShowing: false
			}
		break;

		case 'SET_SINGLE_PERSON':
			return {
				...state,
				singlePersonSlug: action.slug
			}
		break;

		case 'SET_SCROLL_POSITION':
			return {
				...state,
				scrollPosition: action.scrollPosition
			}
		break;

		case 'SET_MENU_IS_SHOWING':
			return {
				...state,
				menuIsShowing: action.val
			}
		break;

		case 'TOGGLE_MENU_IS_SHOWING':
			return {
				...state,
				menuIsShowing: !state.menuIsShowing
			}
		break;

		case 'UPDATE_ARCHIVE_POSTS':
			return {
				...state,
				pageData: {
					...state.pageData,
					[action.dataKey]: {
						...state.pageData[action.dataKey],
						data: {
							...state.pageData[action.dataKey].data,
							posts: action.posts
						}
					}
				}
			}
		break;

		case 'SET_SCROLL_ON_OFF':
			return {
				...state,
				scrollIsActive: action.val
			}
		break;

		case 'SET_SINGLE_PERSON_THUMB_OFFSET_TOP':
			return {
				...state,
				singlePersonThumbOffsetsetTop: action.offset
			}
		break;

		case 'SHOW_SINGLE_NAME':
			const pageKey = state.pagesToShow[0].key

			return {
				...state,
				currentSingleName: [state.pageData[pageKey].title]
			}
		break;

		case 'HIDE_SINGLE_NAME':
			return {
				...state,
				currentSingleName: []
			}
		break;

		case 'SET_SCROLL_DEST':
			return {
				...state,
				scrollDest: action.scrollDest,
				scrollIsAnimating: true,
			}
		break;

		case 'RESET_ANIMATED_SCROLL':
			return {
				...state,
				scrollIsAnimating: false,
				scrollDest: state.scrollPosition,
			}
		break;

		default:
			return state
	}
}

export default App