import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux'
import {Animate} from 'react-move'
import {Switch, Route, Link, useRouteMatch} from 'react-router-dom'

import BottomPageLink from './../BottomPageLink'
import PeopleSingle from './../PeopleSingle'
import Thumb from './Thumb'
import './index.scss';

import {getApiBase} from './../../helpers/helpers.js';

import Footer from './../Footer'

function People(props) {
    const singlePeopleMatch = useRouteMatch("/people/:name");
    if (singlePeopleMatch) props.dispatch({type: 'SET_SINGLE_PERSON', slug: singlePeopleMatch.params.name})
    const [peopleThumbs, setPeopleThumbs] = useState()

    useEffect(() => {
        setPeopleThumbs(Thumbs())
    }, [])

    const Thumbs = () => {
        const numOfPeopleInRow = 3;
        const numOfPeople = props.apiData.people.length;
        const numOfRows = Math.ceil(numOfPeople/numOfPeopleInRow)
        // let numOfThumbs = numOfPeople + Math.ceil(numOfPeople/7);
        // let numOfThumbs = numOfPeople + Math.ceil((numOfPeople + Math.floor(numOfPeople/13))/7) + Math.floor(numOfPeople/13);
        let peopleCounter = 0;
        let thumbs = []
        let positionOfEmptyThumbNextRow, positionOfEmptyThumb;

        for (let i = 0; i < numOfRows; i++) {
            while (positionOfEmptyThumb === positionOfEmptyThumbNextRow) {
                positionOfEmptyThumbNextRow = Math.floor(Math.random()*4);
            }

            positionOfEmptyThumb = positionOfEmptyThumbNextRow;

            for (let p = 0; p < numOfPeopleInRow+1 && peopleCounter < numOfPeople; p++) {
                let thumb;
                if (p == positionOfEmptyThumb) thumb = <div key={Math.random()} className="empty col col4 col8-s"/>
                else {
                    thumb = <Thumb key={Math.random()} data={props.apiData.people[peopleCounter]}/>
                    peopleCounter++;
                }
                thumbs.push(thumb)
            }
        }
        return thumbs;
    }
/*
    const ThumbsAlt = () => {
        const numOfPeople = props.apiData.people.length;
        // let numOfThumbs = numOfPeople + Math.ceil(numOfPeople/7);
        let numOfThumbs = numOfPeople + Math.ceil((numOfPeople + Math.floor(numOfPeople/13))/7) + Math.floor(numOfPeople/13);
        let peopleCounter = 0;
        let thumbs = []

        for (let i = 0; i < numOfThumbs; i++) {
            // let showBlank = i%8 == 0
            let showBlank = i%8 == 0 || (i+1)%16 == 0
            let thumb = showBlank ? <div key={i} className="col col4 col8-s"/> : <Thumb key={i} data={props.apiData.people[peopleCounter]}/>
            // console.log(i, showBlank, peopleCounter);
            thumbs.push(thumb)
            if (!showBlank) peopleCounter++;
        }
        return thumbs;
    }
*/
  return (
    <div className="page people normal">
        <Route path={`/people/:id`} children={({match, ...rest}) => {
            return(
                <PeopleSingle dataKey={props.dataKey}/>
            )}}/>

    	<div className="page-section headline border-bottom">
            <div className="col col16">
    		  <h1>{props.apiData.headertext}</h1>
            </div>
    	</div>
    	<div className="page-section people-list">
            {peopleThumbs}
    	</div>

        <BottomPageLink data={props.apiData.pagelink_bottom}/>
        <Footer/>

    </div>
  );
}

export default connect((state, ownProps) => ({
    apiData: state.App.pageData[ownProps.dataKey].data
}))(People)
