import paper from 'paper'
import {lassoData} from './lassoData'
import {lassoDataObject} from './Data/lassoDataObject'
import {lassoTitles} from './Data/lassoTitles.js'
import {store} from './../../config/configureStore'

export function Lasso(setCurrentLassoNameInReact, startCarousselTimerInReact, stopCarousselTimerInReact){
	var shouldDistortPoints, shouldAnimateToForm, movingMouse, shouldAnimateFormPosition, mouseMoveTimer, gotoNextLassoTimer, tool;
	var lasso, lassoForm, destinationPoints, lassoClones, lassoRevealProcess;
	var animationModes = ['attract', 'repel', /*'rotate', 'distort', 'wiggle'*/], currentAnimationMode = 0 //Math.floor(Math.random()*animationModes.length);
	this.lassoNames = ['rodeo architects', 'landscape', 'architecture', 'urbanism', 'studio', 'social science'];
	this.currentLassoName = 0
	var paperInstance;
	let carousselTime = 7000;
	const isMobile = store.getState().Device.isMobile

	var testDots, testDots2, vectorLine; // for testing
	
	this.init = () => {

		// console.log(lassoDataObject);
		// console.log('running init function');



		// init : variables
		 	shouldDistortPoints = false;
		 	shouldAnimateToForm = false;
		 	movingMouse = false;
		 	shouldAnimateFormPosition = false

		// init : general
			paperInstance = paper.setup('lasso-canvas')
			// paperInstance.remove();
			// paperInstance.install(window);
			tool = new paper.Tool();

		// init lassoes
			// this.actions.initFirst();
			this.actions.initLassoForm();

		// goto first lassoform
			setTimeout(() => {
				this.animation.transformToLassoShape(0)
			}, 400);
			

		// start carousel
			// this.actions.startLassoCarousel();

		// set lassoname in React
			setCurrentLassoNameInReact();

		/*tool.onKeyDown = event => {
			switch(event.key) {
				case 'q':
					this.animation.animateToFormAsWorm(this.generateShape.centerLasso());
				break;

				case 'w':
					this.animation.animateToFormAsWorm(lassoForm);
				break;

				case 'e':
					this.animation.animateToFormAsWorm(this.generateShape.randomShape(500));
				break;

				case 'r':
					this.animation.transformToLassoShape(0)
					// this.animation.animateToFormAsWorm(this.generateShape.lassoShape(0))
				break;

				case 't':
					this.animation.transformToLassoShape(1)
					// this.animation.animateToFormAsWorm(this.generateShape.lassoShape(1))
				break;

				case 'y':
					this.animation.transformToLassoShape(2)
					// this.animation.animateToFormAsWorm(this.generateShape.lassoShape(2))
				break;

				case 'u':
					this.animation.transformToLassoShape(3)
					// this.animation.animateToFormAsWorm(this.generateShape.lassoShape(3))
				break;

				case 'i':
					this.animation.transformToLassoShape(4)
					// this.animation.animateToFormAsWorm(this.generateShape.lassoShape(4))
				break;

				case 'o':
					this.animation.transformToLassoShape(5)
					// this.animation.animateToFormAsWorm(this.generateShape.lassoShape(5))
				break;

				// case 'a':
				// 	this.animation.animateToFormAsWorm(this.generateShape.lineLasso());
				// break;

				case '1':
					this.actions.removeSegments(1);
				break;

				case '2':
					this.actions.removeSegments(2);
				break;

				case '3':
					this.actions.removeSegments(3);
				break;

				case '4':
					this.actions.removeSegments(4);
				break;

				case '5':
					this.actions.removeSegments(5);
				break;

				case '9':
					this.actions.removeSegmentsWidthDelay(10);
				break;

				case 'a':
					this.actions.addSegments(1);
				break;

				case 's':
					this.actions.addSegments(2);
				break;

				case 'd':
					this.actions.addSegments(4);
				break;

				case 'f':
					this.actions.addSegments(5);
				break;

				case 'z':
					var form = [];
					for (var i = 0 ; i < lasso.segments.length; i++) {
						var segment = {
							point: {
								x: lasso.segments[i].point.x,
								y: lasso.segments[i].point.y,
							},
							handleIn: {
								x: lasso.segments[i].handleIn.x,
								y: lasso.segments[i].handleIn.y,
							},
							handleOut: {
								x: lasso.segments[i].handleOut.x,
								y: lasso.segments[i].handleOut.y,
							}
						}
						form.push(segment);
					}
					// console.log(form);
					// console.log(lasso.segments.length);
					for (var key in lassoDataObject) {
						var num = lasso.segments.length/lassoDataObject[key].segments.length
						// console.log(key, lassoDataObject[key].segments.length, num);
					}

				break;

				case 'n':
					// this.actions.gotoNextLasso();
					this.animation.transformToNextLassoShape()
				break;
			}
		}
*/
		// animation
		
			if (!isMobile) paper.view.onFrame = (event) => {
				// console.log('onframe');
				if (shouldDistortPoints) this.animation.distortPoints(4);

				// console.log('lasso segments', lasso.segments[0]);
				// console.log('destinationPoints segements', destinationPoints[0]);

				if (shouldAnimateToForm) {
					// console.log('should animate to form');
					var easingAmount = 10;
					for (var i = 0; i < lasso.segments.length; i++) {
						// point
							var vector = destinationPoints[i].point.subtract(lasso.segments[i].point)
							lasso.segments[i].point = lasso.segments[i].point.add(vector.divide(easingAmount));

						// handleIn
							// var vector = destinationPoints[i].handleIn.subtract(lasso.segments[i].handleIn)
							// lasso.segments[i].handleIn = lasso.segments[i].handleIn.add(vector.divide(easingAmount));

						// handleOut
							// var vector = destinationPoints[i].handleOut.subtract(lasso.segments[i].handleOut)
							// lasso.segments[i].handleOut = lasso.segments[i].handleOut.add(vector.divide(easingAmount));

						// console.log(vector);
						// if (vector.length < 0.01) shouldAnimateToForm = false
					}
				}

			// REVEAL LASSO
			/*
				console.log(event.count);
				var dashTempo = lasso.length/50;
				// var dashTempo = lasso.dashOffset * (1 - Math.pow(1 - dashStep, 1))n
				if (lasso.dashOffset > 0) {
					lasso.dashOffset -= dashTempo;
					if (lasso.dashOffset < 0) {
						lasso.dashOffset = 0;
						lasso.dashArray = null;
					}
				}
				*/
				// lasso.dashOffset = 1500;
				// console.log(event.count);

				if (lassoRevealProcess < 1) {
					lassoRevealProcess += 0.01;
					// var dashAmount = 1 - Math.pow(lassoRevealProcess, 5);
					var dashAmount = Math.pow(1 - lassoRevealProcess, 2)

					lasso.dashOffset = lasso.length*dashAmount
					// lasso.dashOffset = lasso.length*(1-lassoRevealProcess)

					// console.log('revealing', lassoRevealProcess, dashAmount, lasso.length, lasso.dashOffset);
				} else {
					// console.log(lassoRevealProcess);
					lasso.dashOffset = 0;
					lasso.dashArray = null;
				}
				
			}
			

		// mousemove
		
			if (!isMobile) tool.onMouseMove = (event) => {
				if (store.getState().Lasso.onLassoSlide) {
					if (!movingMouse) {
						movingMouse = true;
						this.animation[animationModes[currentAnimationMode]].start();
					}

					this.animation[animationModes[currentAnimationMode]].mouseUpdate(event);

					this.actions.stopLassoCarousel();


					if (mouseMoveTimer) {
						clearTimeout(mouseMoveTimer);
						mouseMoveTimer = null;
					}

					mouseMoveTimer = setTimeout(() => {
						// console.log('MOUSE MOVE TIMER RUN');
						movingMouse = false;
						this.animation[animationModes[currentAnimationMode]].stop();
						this.actions.startLassoCarousel();
					}, this.animation[animationModes[currentAnimationMode]].timeBeforeAnimatingToDefault)
				}
			}

		// resize window
			paper.view.onResize = () => {
				lasso.position = paper.view.center
			}

	}


	// animation functions
		this.animation = {
			distort: {
				timeBeforeAnimatingToDefault: 50,
				start: () => {
					shouldDistortPoints = true;
					shouldAnimateFormPosition = false;
					this.animation.animateToForm(this.generateShape.noiseLasso(80));
				},
				stop: () => {
					shouldDistortPoints = false;
					this.animation.animateToForm(lassoForm);
				},
				mouseUpdate: () => {}
			},
			wiggle: {
				timeBeforeAnimatingToDefault: 50,
				start: () => {
					shouldDistortPoints = true;
					shouldAnimateFormPosition = false
					shouldAnimateToForm = false;
				},
				stop: () => {
					shouldDistortPoints = false;
					this.animation.animateToForm(lassoForm);
				},
				mouseUpdate: () => {}
			},
			attract: {
				timeBeforeAnimatingToDefault: 5000,
				start: () => {
					shouldAnimateFormPosition = false;
				},
				stop: () => {
					this.animation.animateToForm(lassoForm)
				},
				mouseUpdate: (mouseEvent) => {
					var pointsToGoto = new Array(lasso.segments.length);
					// console.log(testDots[0].point);
					for (var i = 0; i < lasso.segments.length; i++) {

						var vector = lassoForm[i].point.subtract(mouseEvent.point)
						// console.log(lassoForm[2].point);
						// testDots[i].fillColor = vector.length < 200 ? 'green' : 'red'
						// testDots[i].position = vector.length < 200 ? mouseEvent : lassoForm[i];
						pointsToGoto[i] = {
							point: vector.length < 200 ? mouseEvent.point : lassoForm[i].point
						}
					}

					// console.log('attract');
					// console.log(pointsToGoto);
					this.animation.animateToForm(pointsToGoto)
				}
			},
			repel: {
				timeBeforeAnimatingToDefault: 5000,
				start: () => {
					shouldAnimateFormPosition = false;
				},
				stop: () => {
					this.animation.animateToForm(lassoForm)
				},
				mouseUpdate: (mouseEvent) => {
					shouldAnimateToForm = true;
					var pointsToGoto = new Array(lasso.segments.length);

					for (var i = 0; i < lasso.segments.length; i++) {
						var vector = lassoForm[i].subtract(mouseEvent.point)
						// testDots[i].fillColor = vector.length < 120 ? 'blue' : 'red' // for testing

							var activeDist = 120

							if (vector.length < activeDist) {
								var v = vector.clone();
								v.length = activeDist - vector.length;

								var bottom = lassoForm[i].add(v);
								pointsToGoto[i] = bottom;

								// without animating
									// lasso.segments[i].point = bottom

								// drawing anchor dots and lines
									// var top = lassoForm[i];
									// testDots2[i].position.set(bottom)
									// vectorLine[i].segments[0].point.set(top)
									// vectorLine[i].segments[1].point.set(bottom)
							} else {
								pointsToGoto[i] = lassoForm[i];
							}

						// testDots[i].position = vector.length < 200 ? mouseEvent : lassoForm[i];
						// pointsToGoto[i] = vector.length < 200 ? mouseEvent.point : lassoForm[i];
					}

					this.animation.animateToForm(pointsToGoto)
				}
			},
			rotate: {
				timeBeforeAnimatingToDefault: 2000,
				animationTime: 3,
				showLassoClones: true,
				start: () => {
					this.animation.setCurrentAnimationTime(4)
					shouldAnimateToForm = false;
					shouldAnimateFormPosition = true;
				},
				stop: () => {
					this.animation.setCurrentAnimationTime(4)
					for (var i = 0; i < lassoClones.length; i++) {
							var destPoint = new paper.Point(window.innerWidth/2, window.innerHeight/2)
							lassoClones[i].data.destPosition = destPoint;
							lassoClones[i].data.destOpacity = 0;
						}
				},
				mouseUpdate: (mouseEvent) => {
					var mousePosX = (mouseEvent.point.x/window.innerWidth-0.5)*2
					var mousePosY = (mouseEvent.point.y/window.innerHeight-0.5)*2

					// console.log('rotate update', mouseEvent.delta);

					for (var i = 0; i < lassoClones.length; i++) {
						var destPoint = new paper.Point(paper.view.center.x + (i + 1) * 10 * mousePosX, paper.view.center.y + (i + 1) * 10 * mousePosY)
						lassoClones[i].data.destPosition = destPoint;
						lassoClones[i].data.destOpacity = 1;

						// console.log(destPoint);

							// lassoClones[i].position.y = window.innerHeight/2 + i * 10 * mousePosY

							// lassoClones[i].rotate(i*mouseEvent.delta.y*0.01)

							// lassoClones[i].strokeColor.brightness = i * 0.05
							// console.log(lassoClones[i].strokeColor.red);
						}
				}
			},
			getCurrentAnimationTime: () => {
				return this.animation[animationModes[currentAnimationMode]].animationTime;
			},
			setCurrentAnimationTime: (time) => {
				this.animation[animationModes[currentAnimationMode]].animationTime = time;
			},
			animateToForm: (pointsToAnimateTo) => {
				// set destinationPoints as wished
				for (var i = 0; i < lasso.segments.length; i++) {
					destinationPoints[i] = pointsToAnimateTo[i];
				}

				// set shouldAnimateToForm
				shouldAnimateToForm = true;
			},

			animateToFormAsWorm: (pointsToAnimateTo) => {
				// shouldAnimateToForm = true;
				
				for (var i = 0; i < lasso.segments.length; i++) {
					// destinationPoints[i] = lasso.segments[i].point;
					// console.log(destinationPoints[i]);

					(function(i) {
						setTimeout(() => {
							destinationPoints[i] = pointsToAnimateTo[i];

							// for (var p = i; p < lasso.segments.length; p++) {
							// 	destinationPoints[p] = pointsToAnimateTo[i];

							// }
							// console.log(destinationPoints[i]);
						}, 12 * i)
					})(i)
				}

				// set shouldAnimateToForm
				shouldAnimateToForm = true;
			},
			distortPoints: (distortAmount) => {
				for (var i = 0; i < lasso.segments.length; i++) {
					var segment = lasso.segments[i];
					// .multiply(paper.Point.random())
					var noisePoint = new paper.Point(distortAmount, distortAmount).multiply(paper.Point.random()).subtract(distortAmount*0.5);
					// var newPoint = noisePoint.substract(new paper.Point(50,50));
					segment.point = segment.point.add(noisePoint) 
					// console.log(segment.point);
				}
			},
			hideLasso: () => {
				// this.animation.animateToForm(this.generateShape.noiseLasso(40));
				var tween = lasso.tweenTo({strokeColor: 'white'}, {duration: 300})
			},

			showLasso: () => {
				// this.animation.animateToForm(lassoForm);
				var tween = lasso.tweenTo({strokeColor: 'black'}, {duration: 300})
			},

			transformToLassoShape: (lassoNum) => {
				// find length diffenrence
					var currentLassoLength = lasso.segments.length;

					var currentLassoObj = lassoDataObject[this.lassoNames[lassoNum]];

					var destinationLassoLength = currentLassoObj.segments.length;
					var lengthDiff = Math.abs(destinationLassoLength - currentLassoLength);

				// set length of new lasso
					if (destinationLassoLength > currentLassoLength) {
						// add segments
						// console.log('adding segments', lengthDiff);
						this.actions.addSegments(lengthDiff);

					} else if (destinationLassoLength < currentLassoLength) {
						// remove segments
						// console.log('removing segments', lengthDiff);
						this.actions.removeSegments(lengthDiff);
					}

				// set lasso segments to new position
					for (var i = 0; i < lasso.segments.length; i++) {
						lasso.segments[i].point.x = currentLassoObj.segments[i].point.x;
						lasso.segments[i].point.y = currentLassoObj.segments[i].point.y;

						lasso.segments[i].handleIn.x = currentLassoObj.segments[i].handleIn.x;
						lasso.segments[i].handleIn.y = currentLassoObj.segments[i].handleIn.y;

						lasso.segments[i].handleOut.x = currentLassoObj.segments[i].handleOut.x;
						lasso.segments[i].handleOut.y = currentLassoObj.segments[i].handleOut.y;
					}


					// lassoForm = lasso.segments;
					// console.log('lassoform', lassoForm[2].point);
					// console.log('current lasso segments', lasso.segments[2].point);



				// set lasso scale
					let landscapeMode = window.innerWidth > window.innerHeight;
					let lassoScale = landscapeMode ? window.innerWidth/1200 * currentLassoObj.size.landscape : window.innerWidth/900 * currentLassoObj.size.portrait;
					let strokeWidth = landscapeMode ? window.innerWidth/1280 * 10 : window.innerWidth/1280 * 18

					lasso.scale(lassoScale, lasso.bounds.topLeft)
					lasso.position = paper.view.center

				// reset lassoform & destinationPoints
					for (var i = 0; i < lasso.segments.length; i++) {
						var x = lasso.segments[i].point.x, y = lasso.segments[i].point.y;

						lassoForm[i] = {
							point: new paper.Point(x, y)
						}

						destinationPoints[i] = {
							point: new paper.Point(x, y)
						}

						// add noise to lassopoints
						if (!isMobile) {
							var noiseAmount = isMobile ? 40 : 100
							lasso.segments[i].point.x = x + Math.random()*noiseAmount - noiseAmount/2
							lasso.segments[i].point.y = y + Math.random()*noiseAmount - noiseAmount/2
						}
							// console.log('x', x);
					}


				// draw when revealed
					// console.log(lasso.length);
					if (!isMobile) {
						lasso.dashArray = [lasso.length, lasso.length]
						lasso.dashOffset = lasso.length
						lassoRevealProcess = 0;
						shouldAnimateToForm = true;
					}
					// setTimeout(() => {shouldAnimateToForm = true;}, 200)


				// set lassotitle
					this.currentLassoName = lassoNum;
					setCurrentLassoNameInReact();

					



				// console.log(currentLassoLength, destinationLassoLength);
					
			},

			transformToNextLassoShape: () => {

					var nextLassoNum = this.currentLassoName + 1;
					if (nextLassoNum >= this.lassoNames.length) nextLassoNum = 0;
					this.currentLassoName = nextLassoNum;
					setCurrentLassoNameInReact();

					setTimeout(() => {
						this.animation.hideLasso();
					}, 150)

					setTimeout(() => {
						this.animation.transformToLassoShape(nextLassoNum)
						this.animation.showLasso();
					}, 450)

			}
		}


	// generate shapes
		this.generateShape = {
			randomShape: (size) => {
				size = 1000
				var form = new Array(lasso.segments.length);

				//generate points
					for (var i = 0; i < form.length; i++) {
						var lassoObj = lassoDataObject['studio'].segments

						if (i > 1) {
							var angle = 0;
							// while(angle < 100) {
								var point1 = form[i-1].point.subtract(paper.view.center)
								var point2 = point1.subtract(form[i-2].point.subtract(paper.view.center))
								point2.angle += Math.random()*135

								var point = point2.add(point1)
								// console.log(point);
							// }

								// var point = new paper.Point(Math.random()*size-size*0.5, Math.random()*size-size*0.5);
							// console.log('vector', vector);
						} else {
								var point = new paper.Point(size*0.75+Math.random()*size*0.25-size*0.5, size*0.75+Math.random()*size*0.25-size*0.5);
						}

						point = point.add(paper.view.center)

						form[i] = {
							point: point,
							// handleIn: null,
							// handleOut: null
							handleIn: new paper.Point(-100, 0),
							handleOut: new paper.Point(100, 0)
						}
						// lasso.smooth({type: 'continuous'})
					}

				// generate handles
					for (var i = 0; i < form.length; i++) {
						if (i > 0) {
							
							// while(angle < 100) {
								var point1 = form[i-1].point
								var point2 = form[i].point
								var vector = point1.add(point2).normalize()
								// console.log(vector);

								var handleIn, handleOut;
							// }

								// var point = new paper.Point(Math.random()*size-size*0.5, Math.random()*size-size*0.5);
							// console.log('vector', vector);
						// } else {
						// 		var point = new paper.Point(size*0.75+Math.random()*size*0.25-size*0.5, size*0.75+Math.random()*size*0.25-size*0.5);
						// }

						// point = point.add(paper.view.center)

						// form[i].handleIn = handleIn;
						// form[i].handleOut = handleOut;
							// handleIn: new paper.Point(lassoObj[i].handleIn.x, lassoObj[i].handleIn.y),
							// handleOut: new paper.Point(lassoObj[i].handleOut.x, lassoObj[i].handleOut.y)
							// handleIn: new paper.Point(-100, 0),
							// handleOut: new paper.Point(100, 0)
						}
						// lasso.smooth({type: 'continuous'})
					}

				// console.log(form);
				// console.log(lassoDataObject[this.lassoNames[0]]);
				return form;
			},

			lassoShape: (lassoNum) => {
				var form = new Array(lasso.segments.length);
				var currentLassoObj = lassoDataObject[this.lassoNames[lassoNum]].segments;

				var values = this.helpers.getIntDividedIntoMultiple(lasso.segments.length, currentLassoObj.length, 1);
				// console.log(values);

				var num = 0;
				for (var i = 0; i < values.length; i++) {
					var index = i;

					for (var p = 0; p < values[i]; p++) {
						// console.log(p, values[i]);
						var point = new paper.Point(currentLassoObj[index].point.x, currentLassoObj[index].point.y)

						if (values[i] == 1) {
							var handleIn = new paper.Point(currentLassoObj[index].handleIn.x, currentLassoObj[index].handleIn.y)
							var handleOut = new paper.Point(currentLassoObj[index].handleOut.x, currentLassoObj[index].handleOut.y)
						} else if (p == 0) {
							var handleIn = new paper.Point(currentLassoObj[index].handleIn.x, currentLassoObj[index].handleIn.y)
							var handleOut = new paper.Point(0,0);
						} else if (p == values[i]-1) {
							var handleIn = new paper.Point(0,0)
							var handleOut = new paper.Point(currentLassoObj[index].handleOut.x, currentLassoObj[index].handleOut.y)
						} else {
							var handleIn = new paper.Point(0,0)
							var handleOut = new paper.Point(0,0)
						}

						form[num] = {
							point: point,
							handleIn: handleIn,
							handleOut: handleOut
						}

						num++;
					}
				}

				// for (var i = 0; i < form.length; i++) {

				// 	let index = currentLassoObj[i] ? i : currentLassoObj.length-1;

				// 	var point = new paper.Point(currentLassoObj[index].point.x, currentLassoObj[index].point.y)
				// 	var handleIn = new paper.Point(currentLassoObj[index].handleIn.x, currentLassoObj[index].handleIn.y)
				// 	var handleOut = new paper.Point(currentLassoObj[index].handleOut.x, currentLassoObj[index].handleOut.y)

				// 	form[i] = {
				// 		point: point,
				// 		handleIn: handleIn,
				// 		handleOut: handleOut
				// 	}
				// }

				// console.log(lassoDataObject[this.lassoNames[0]]);

				// console.log(form);
				return form;	
			},

			noiseLasso: (distortAmount) => {
				var form = new Array(lasso.segments.length);
				for (var i = 0; i < form.length; i++) {
					var noisePoint = new paper.Point(distortAmount, distortAmount).multiply(paper.Point.random()).subtract(distortAmount*0.5);
					var point = lasso.segments[i].point.add(noisePoint) 
					
					form[i] = point;
				}
				return form;	
			},

			centerLasso: () => {
				var form = new Array(lasso.segments.length);
				for (var i = 0; i < form.length; i++) {
					form[i] = new paper.Point(window.innerWidth/2,window.innerHeight/2);
				}
				return form;	
			},

			lineLasso: () => {
				var form = new Array(lasso.segments.length);
				var offsetX = 150, lengthX = window.innerWidth-offsetX*2

				for (var i = 0; i < form.length; i++) {
					var xPos = lengthX/form.length*i+offsetX
					form[i] = new paper.Point(xPos, window.innerHeight/2);
				}
				return form;	
			}

		}


	// actions
		this.actions = {
			removeSegmentsWidthDelay: (num) => {
				for (var i = 0; i < num; i++) {
					(function(i) {
						setTimeout(() => {
							this.actions.removeSegment(1)
						}, 100 * i)
					})(i)
				}
			},

			removeSegments: (num) => {
				for (var i = 0; i < num; i++) {
					var index = Math.floor(Math.random()*lasso.segments.length);
					lasso.removeSegment(index)
				}

				// console.log(lasso.segments.length);
			},

			addSegments: num => {
				for (var i = 0; i < num; i++) {
					if (lasso.segments.length < destinationPoints.length) lasso.add(lasso.segments[1])
				}

				// console.log(lasso.segments.length);
				// console.log(destinationPoints.length);
			},

			initFirstLasso: () => {
				this.actions.initLasso('black');
				// console.log('init first lasso');

				// testDots = new Array(lasso.segments.length) // for testing
				// testDots2 = new Array(lasso.segments.length) // for testing
				// vectorLine = new Array(lasso.segments.length) // for testing

				for (var i = 0; i < lasso.segments.length; i++) {
					var point = new paper.Point(lasso.segments[i].point)

					// testDots[i] = paper.Path.Circle(point, 4); // for testing
					// testDots[i].fillColor = 'black' // for testing

					// testDots2[i] = paper.Path.Circle(point, 1); // for testing
					// testDots2[i].fillColor = 'black' // for testing

					// vectorLine[i] = new paper.Path.Line();
					// vectorLine[i].strokeColor = 'black';
					// vectorLine[i].strokeWidth = 2;
				}

			},
			initNewLasso: () => {
				this.actions.initLasso('white');
			},
			initLassoForm: () => {
				// init : lasso
					 
					// SIZE
						// let lassoScale = window.innerWidth/4300;
						const landscapeMode = window.innerWidth > window.innerHeight;
						let lassoScale = landscapeMode ? window.innerWidth/3650 : window.innerWidth/3400;
						let strokeWidth = landscapeMode ? window.innerWidth/1280 * 10 : window.innerWidth/1280 * 18

					// PATH
						lasso = new paper.Path()
						// lasso = new paper.Path(lassoData[this.lassoNames[this.currentLassoName]])


					// FILL PATH WITH POINTS
						for (let i = 0; i < 42; i++) {
							lasso.add(new paper.Point(0,0))
						}

					// SET PATH COLORS ETC.
						// lasso.smooth({type: 'continuous'})
						lasso.strokeColor = 'black'
						lasso.strokeWidth = strokeWidth
						lasso.scale(lassoScale, lasso.bounds.topLeft)
						lasso.position = paper.view.center

						// lasso.fullySelected = true;

				// init : lassoPointsBase + destinationPoints
					lassoForm = new Array(lasso.segments.length);
					destinationPoints = new Array(lasso.segments.length);

					for (var i = 0; i < lasso.segments.length; i++) {
						var point = new paper.Point(lasso.segments[i].point)

						lassoForm[i] = lasso.segments[i];
						destinationPoints[i] = lasso.segments[i];


					// 	var pointCenter = new paper.Point(window.innerWidth/2, window.innerHeight/2)
					// 	lasso.segments[i].point = pointCenter;
					// 	destinationPoints[i] = pointCenter;
					}

					// shouldAnimateToForm = true;
					// 
			},
			advanceAnimationType: () => {
				currentAnimationMode++;
				if (currentAnimationMode >= animationModes.length) currentAnimationMode = 0;
			},
			startLassoCarousel: () => {
				console.log('START LASSO CAROUSSEL');
				this.actions.gotoNextLassoWithDelay()
				startCarousselTimerInReact(carousselTime)
			},
			stopLassoCarousel: () => {
				console.log('STOP LASSO CAROUSSEL');
				stopCarousselTimerInReact()
				if (mouseMoveTimer) {
					clearTimeout(mouseMoveTimer)
					mouseMoveTimer = null;
				}
				if (gotoNextLassoTimer) {
					// console.log('stopping lasso caroussel');
					clearTimeout(gotoNextLassoTimer)
					gotoNextLassoTimer = null;
					// console.log('gotoNextLassoTimer', gotoNextLassoTimer);
				}
			},
			gotoNextLassoWithDelay: () => {
				// console.log(gotoNextLassoTimer);
				if (gotoNextLassoTimer) {
					clearTimeout(gotoNextLassoTimer)
					gotoNextLassoTimer = null;
				}
				gotoNextLassoTimer = setTimeout(() => {
					// this.actions.gotoNextLasso();
					this.animation.transformToNextLassoShape()
					this.actions.gotoNextLassoWithDelay();
				},carousselTime) 
			},
			gotoLassoId: (newLassoId) => {
				this.animation.hideLasso();
				setTimeout(() => {
					this.actions.changeLassoTo(newLassoId)
					this.animation.showLasso();
				}, 150)
			},
			gotoNextLasso: () => {
				// console.log('going to next lasso');

				this.animation.hideLasso();

				setTimeout(() => {
					this.actions.changeLasso()
					this.animation.showLasso();
					setCurrentLassoNameInReact()
					paper.view.update();
				}, 150)
			},
			changeLasso: () => {
				shouldAnimateToForm = false;
				this.currentLassoName++;
				if (this.currentLassoName >= this.lassoNames.length) this.currentLassoName = 0;
				this.actions.initNewLasso();
			},
			changeLassoTo: (newLassoId) => {
				shouldAnimateToForm = false;
				this.currentLassoName = newLassoId;
				this.actions.initNewLasso();
			},
			getCurrentLassoName: () => {
				return lassoTitles[this.currentLassoName];
			},
			getAllLassoNames: () => {
				return lassoTitles;
			},
			destroy: () => {
				this.actions.stopLassoCarousel();
				paperInstance.remove()
			}
		}

	this.helpers = {
		getIntDividedIntoMultiple: (dividend, divisor, multiple) => {
		    var values = [];
		    while (dividend> 0 && divisor > 0)
		    {
		        var a = Math.round(dividend/ divisor / multiple) * multiple;
		        dividend -= a;
		        divisor--;
		        values.push(a);
		    }

		    return values;
		}
		

	// window.onload = () => {
	// 	console.log('hej');
	// 	this.init();
	// }
	}
}