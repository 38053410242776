import React from 'react';
import LazyImageComponent from './../LazyImageComponent'
import LinkWrapper from './../LinkWrapper'

export const Text = ({data}) => (
	<div className="text" dangerouslySetInnerHTML={{__html: data.text}}/>
	)

export const Image = ({data}) => (
	<LazyImageComponent width={data.image.sizes[`halfscreen-width`]} height={data.image.sizes[`halfscreen-height`]} url={data.image.sizes.halfscreen}/>
	)

export const Button = ({data}) => (
		<LinkWrapper><a className="btn_download_file" href={data.file} target="_blank">{data.buttontext}</a></LinkWrapper>
	)