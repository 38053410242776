import React from 'react';
import {Link} from 'react-router-dom'
import {connect} from 'react-redux'

import {ReactComponent as LogoRodeo} from './../../../assets/icons/logo_rodeo.svg'
import {ReactComponent as LogoArchitectsVertical} from './../../../assets/icons/logo_architects_vertical.svg'
import {ReactComponent as LogoArchitectsHorizontal} from './../../../assets/icons/logo_architects_horizontal.svg'

import './index.scss'

import LinkWrapper from './../LinkWrapper'

function BrandlogoMain(props) {
  return (
  		<div className={`${props.menuIsShowing ? 'menu-open' : ''} ${props.brandlogoIsMovedBack && props.isMobileSize ? 'back' : ''}`}>
			<div className="brandlogo main"><LinkWrapper><Link to="/"><LogoRodeo/></Link></LinkWrapper></div>
    		<div className="brandlogo secondary"><LinkWrapper><Link to="/"><LogoArchitectsVertical/></Link></LinkWrapper></div>
		</div>
  );
}

export default connect((state) => ({
	menuIsShowing: state.App.menuIsShowing,
	brandlogoIsMovedBack: state.Brandlogo.brandlogoIsMovedBack,
	isMobileSize: state.Device.isMobileSize
}))(BrandlogoMain)