import React from 'react';
import LazyImageComponent from './../LazyImageComponent'
import ScrollVisibilityComponent from './../ScrollVisibilityComponent'
import LinkWrapper from './../LinkWrapper'
import {Link} from 'react-router-dom'

const ImageWithLink = (link, width, height, url) => {
	return (
		link ? 
			<ScrollVisibilityComponent>
				<LinkWrapper>
		        	<Link to={`/projects/${link}`}>
						<LazyImageComponent width={width} height={height} url={url}/>
		        	</Link>
				</LinkWrapper>
			</ScrollVisibilityComponent>
			: 
			<ScrollVisibilityComponent>
				<LazyImageComponent width={width} height={height} url={url}/>
			</ScrollVisibilityComponent>
		)
}

export const Template1 = ({data, firstSection}) => {
	return(
		<ScrollVisibilityComponent>
			<div className={`page-section studio template1 ${!firstSection ? 'border-top' : ''}`} id={data.title.toLowerCase()}>
				<h3 className="section-title">{data.title}</h3>
	    		<div className="col col5 colmar3r bottom colmar0r-s col16-s">
	    			<div className="inner">
	    				{ImageWithLink(data.image_left_link.post_name, data.image_left.sizes[`halfscreen-width`], data.image_left.sizes[`halfscreen-height`], data.image_left.sizes.halfscreen)}
	    			</div>
	    		</div>
	    		<div className="col col8 col16-s">
	    			<div className="inner">
	        			<ScrollVisibilityComponent>
	    					<div className="text top" dangerouslySetInnerHTML={{__html: data.text}}/>
	        			</ScrollVisibilityComponent>
	    				{ImageWithLink(data.image_right_link.post_name, data.image_right.sizes[`halfscreen-width`], data.image_right.sizes[`halfscreen-height`], data.image_right.sizes.halfscreen)}
	    			</div>
	    		</div>
	    	</div>
		</ScrollVisibilityComponent>
		)
}

export const Template2 = ({data, firstSection}) => {
	return(
		<ScrollVisibilityComponent>
			<div className={`page-section studio template2 ${!firstSection ? 'border-top' : ''}`} id={data.title.toLowerCase()}>
	    		<h3 className="section-title">{data.title}</h3>
	    		<div className="row">
		    		<div className="col col8 colmar8l colmar0l-s col16-s">
		    			<div className="inner"> 
		        			<ScrollVisibilityComponent>
		    					<div className="text" dangerouslySetInnerHTML={{__html: data.text}}/>
		        			</ScrollVisibilityComponent>
		    			</div>
		    		</div>
	    		</div>
	    		<div className="row">
		    		<div className="col col6 colmar10r colmar0r-s col16-s">
		    			<div className="inner"> 
	    					{ImageWithLink(data.image_link.post_name, data.image.sizes[`halfscreen-width`], data.image.sizes[`halfscreen-height`], data.image.sizes.halfscreen)}
		    			</div>
		    		</div>
	    		</div>
	    	</div>
		</ScrollVisibilityComponent>
		)
}

export const Template3 = ({data, firstSection}) => {
	return(
		<ScrollVisibilityComponent>
			<div className={`page-section studio template3 ${!firstSection ? 'border-top' : ''}`} id={data.title.toLowerCase()}>
	    		<h3 className="section-title">{data.title}</h3>
	    		<div className="row">
		    		<div className="col col8 colmar8l colmar0l-s col16-s">
		    			<div className="inner"> 
	    					{ImageWithLink(data.image_link.post_name, data.image.sizes[`halfscreen-width`], data.image.sizes[`halfscreen-height`], data.image.sizes.halfscreen)}
		    			</div>
		    		</div>
	    		</div>
	    		<div className="row">
		    		<div className="col col8 colmar8r colmar0r-s col16-s">
		    			<div className="inner"> 
		        			<ScrollVisibilityComponent>
		    					<div className="text" dangerouslySetInnerHTML={{__html: data.text}}/>
		        			</ScrollVisibilityComponent>
		    			</div>
		    		</div>
	    		</div>
	    	</div>
		</ScrollVisibilityComponent>
		)
}

export const Template4 = ({data, firstSection}) => {
	return(
		<ScrollVisibilityComponent>
			<div className={`page-section studio template4 ${!firstSection ? 'border-top' : ''}`} id={data.title.toLowerCase()}>
	    		<h3 className="section-title">{data.title}</h3>
	    		<div className="row">
		    		<div className="col col8 colmar8l colmar0l-s col16-s">
		    			<div className="inner"> 
	    					{ImageWithLink(data.image_top_link.post_name, data.image_top.sizes[`halfscreen-width`], data.image_top.sizes[`halfscreen-height`], data.image_top.sizes.halfscreen)}
		    			</div>
		    		</div>
	    		</div>
	    		<div className="row">
	    			<div className="col col6 colmar2r bottom col16-s colmar0r-s">
		    			<div className="inner"> 
	    					{ImageWithLink(data.image_bottom_link.post_name, data.image_bottom.sizes[`halfscreen-width`], data.image_bottom.sizes[`halfscreen-height`], data.image_bottom.sizes.halfscreen)}
		    			</div>
		    		</div>
		    		<div className="col col8 bottom col16-s">
		    			<div className="inner"> 
		        			<ScrollVisibilityComponent>
		    					<div className="text" dangerouslySetInnerHTML={{__html: data.text}}/>
		        			</ScrollVisibilityComponent>
		    			</div>
		    		</div>
	    		</div>
	    	</div>
		</ScrollVisibilityComponent>
		)
}