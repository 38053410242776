import React, {useState, useEffect, useRef} from 'react';
import {Link} from 'react-router-dom'
import {CSSTransition} from 'react-transition-group';

import {connect} from 'react-redux'
import {Animate} from 'react-move'
import { easeExpInOut, easeCubicInOut } from 'd3-ease'
import BezierEasing from 'bezier-easing'

import {getApiBase, getPageLink, playPauseVideo} from './../../helpers/helpers.js';

import './../../../scss/variables/index.scss';
import './index.scss';

import {ReactComponent as ArrowSlider} from './../../../assets/icons/arrowSlider.svg'
import {ReactComponent as ArrowLink} from './../../../assets/icons/arrowLink.svg'
import LinkWrapper from './../LinkWrapper'

import {FullScreenSlide, CenterSlide, TwoColSlide, LassoSlide, PeopleSlide, VideoSlide} from './SliderSections.js';



function Slide(props) {
  var bezierCurve = BezierEasing(0.84, 0.07, 0.07, 1.01);
  const [middleSlide] = useState(Math.round(props.numOfSlides%2 === 0 ? props.numOfSlides/2 : props.numOfSlides/2-1));
  const [heightOfSlidertrack] = useState(props.numOfSlides * 100);
  const [sliderTrackCeilLow] = useState(middleSlide*-100);
  const [sliderTrackCeilHigh] = useState(props.numOfSlides%2 === 0 ? middleSlide*100 : Math.round(props.numOfSlides/2)*100);
  const [slidePos, setSlidePos] = useState();
  const [shouldAnimiate, setShouldAnimate] = useState(false)
  const initRef = useRef()

  const map = (value, x1, y1, x2, y2) => (value - x1) * (y2 - x2) / (y1 - x1) + x2;

  useEffect(() => {
    let pos = map(((props.id + props.currentSlide + middleSlide) * 100) % heightOfSlidertrack, 0, heightOfSlidertrack, sliderTrackCeilLow, sliderTrackCeilHigh)
    setSlidePos(pos);
    if (initRef.current) setShouldAnimate(Math.abs(pos) <= 100 ? true : false)
  }, [props.currentSlide])

  useEffect(() => {
    initRef.current = true;
  }, [])

  return(
    <div className={`slide ${shouldAnimiate ? 'animate' : ''}`} style={{transform: `translateY(${slidePos}%) translateZ(0)`}}>
          {props.children}
        </div>
    )
}

function Frontpage(props) {

  const slideAnimationSpeed = props.screenSize > 800 ? 1200 : 700;
  const [currentSlideContinous, setCurrentSlideContinous] = useState(0);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [shouldSlideMoveDown, setShouldSlideMoveDown] = useState(false);
  const [mousePos, setMousePos] = useState([0,0])
  const [showMouseCursor, setShowMouseCursor] = useState(false);
  const [slideIsAnimating, setSlideIsAnimating] = useState(false);
  const [mouseOverLink, setMouseOverLink] = useState(false);
  const [cursorRotation, setCursorRotation] = useState(180)
  
  const [touchStartY, setTouchStartY] = useState(null);

  useEffect(() => {
    props.dispatch({type: 'INIT_CURSOR_ROTATION'})
    isOnLassoSlide(0)
    isOnVideoSlide(0);
  }, [])

  const changeCurrentSlide = (newSlide) => {
      setCurrentSlideContinous(newSlide)
      let num = (newSlide + props.apiData.length*10000)%props.apiData.length;
      setCurrentSlide(num)

      isOnLassoSlide(num);
      isOnVideoSlide(num);
  }

  const isOnLassoSlide = (num) => {
    let isOnLassoSlide = props.apiData[num].acf_fc_layout == 'lassoslide'
      
      props.dispatch({
        type: 'SET_IS_ON_LASSOSLIDE',
        val: isOnLassoSlide
      })
  }

  const isOnVideoSlide = (num) => {
    // pause all videos
      for (let i = 0; i < props.apiData.length; i++) {
        if (props.apiData[i].acf_fc_layout === 'videoslide') {
          playPauseVideo(false, 'v'+i)
        }      
      }

    // play video if on videoslide
      if (props.apiData[num].acf_fc_layout == 'videoslide') {
          playPauseVideo(true, 'v'+num)
      }
  }

  const clicking = () => {
    if (!slideIsAnimating) {
      setSlideIsAnimating(true)
      let newSlide = shouldSlideMoveDown ? currentSlideContinous + 1 : currentSlideContinous - 1;
      changeCurrentSlide(newSlide)
      tjeckSlideDir(mousePos[1], newSlide)
      setTimeout(() => {
        setSlideIsAnimating(false)
      }, slideAnimationSpeed)
    }   
  }

  const scrolling = (deltaY) => {
    if (!slideIsAnimating) {
      setSlideIsAnimating(true);
      let newSlide = deltaY > 0 ? currentSlideContinous + 1: currentSlideContinous - 1;
      changeCurrentSlide(newSlide);
      setTimeout(() => {
        setSlideIsAnimating(false)
      }, slideAnimationSpeed)
    }
  }

  const mouseMove = (e) => {
    setMousePos([e.clientX, e.clientY])
    tjeckSlideDir(e.clientY);
  }

  const tjeckSlideDir = (mousePos) => {
    let newSlideDir = mousePos > window.innerHeight/2 ? true : false;
    if (newSlideDir !== shouldSlideMoveDown) {
      setShouldSlideMoveDown(newSlideDir)
      // setCursorRotation(prevVal => prevVal+=180)
      props.dispatch({type: 'ROTATE_CURSOR'})
    }
  }

  const hoverLink = (isOverLink) => {
    setMouseOverLink(isOverLink)
    setShowMouseCursor(!isOverLink)
  }

  const mobileTouchStart = (touchObj) => {
    setTouchStartY(touchObj.pageY);
  }

  const mobileTouchMove = (touchObj) => {
    let dist = touchObj.pageY - touchStartY;
    if (Math.abs(dist) >= 50) {
      if (!slideIsAnimating) {
        setSlideIsAnimating(true)
        let dir = dist > 0 ? -1 : 1;
        let newSlide = currentSlideContinous + dir;
        changeCurrentSlide(newSlide);
        setTimeout(() => {
          setSlideIsAnimating(false)
        }, slideAnimationSpeed)
      }
    }
  }

  const currentSlideData = props.apiData[currentSlide];
  let index = 0, timesToRun = 2;

  const createSlides = () => {
    let slides = [];
    let timesToRun = props.apiData.length > 4 ? 1 : props.apiData.length > 2 ? 2 : props.apiData.length === 2 ? 3 : 5;
    let numOfSlides = props.apiData.length * timesToRun;
    let key = 0;
    for (let p = 0; p < timesToRun; p++) {
      for (let i = 0; i < props.apiData.length; i++) {
        let singleData = props.apiData[i];
        let slide = <Slide key={key} id={key} currentSlide={-currentSlideContinous + numOfSlides*10000} numOfSlides={numOfSlides}>
                      {singleData.acf_fc_layout === 'lassoslide' ? <LassoSlide/> :
                      singleData.layout === 'fullscreen' ? <FullScreenSlide title={singleData.title} img1={singleData.image_1}/> :
                      singleData.layout === 'center' ? <CenterSlide title={singleData.title} img1={singleData.image_1} screenSize={props.screenSize}/> :
                      singleData.layout === '2col' ? <TwoColSlide title={singleData.title} img1={singleData.image_1} img2={singleData.image_2}/> :
                      singleData.acf_fc_layout === 'peopleslide' ? <PeopleSlide/> : 
                      singleData.acf_fc_layout === 'videoslide' ? <VideoSlide videoservice="vimeo" videoId={singleData.vimeo_id} vid={'v'+key}/> : null}
                    </Slide>
        slides.push(slide)
        key++;
      }
    }

    return slides;
  }

  return (
    <div className="page frontpage normal">
    	<div className="frontpage-slider" 
        onClick={(e) => !mouseOverLink ? clicking() : null} 
        onMouseMove={(e) => mouseMove(e)} 
        onWheel={(e) => scrolling(e.deltaY)} 
        onTouchStart={e => mobileTouchStart(e.changedTouches[0])}
        onTouchMove={e => mobileTouchMove(e.changedTouches[0])} 
      >
        {/*!props.isMobile ? <div className={`cursor ${showMouseCursor ? 'show' : ''}`} style={{top:mousePos[1]-20, left: mousePos[0]-20, transform: `scale(${showMouseCursor ? 1 : 0}) rotateZ(${cursorRotation}deg)`}}><ArrowSlider/></div> : null*/}
          <div className="frontpage-slider-inner">{createSlides()}</div>
          <div className={`slidedata`}>
            <h2 className="title">
            {props.apiData.map((singleData, key) => {
              return (
                  <CSSTransition key={key} in={currentSlide === key} timeout={{enter: 1450, exit: 600}} classNames="datatitle" unmountOnExit={true}>
                    <div className={`inner ${singleData.textcolor}`}>{singleData.title}</div>
                  </CSSTransition>
                )})}
            {/*props.allLassoNames.map((data, key) => {
                return(
                  <CSSTransition key={key} in={props.apiData[currentSlide].acf_fc_layout == 'lassoslide' && data == props.currentLassoName} timeout={{enter: 1470, exit: 700}} classNames="lassolink" unmountOnExit={true}>
                      <span className={`lassotitle ${key !== 0 ? 'poster' : ''}`}>{data}</span>
                  </CSSTransition>
                ) 
              })*/}
            </h2>
            <div className="link" onMouseEnter={() => hoverLink(true)} onMouseLeave={() => hoverLink(false)}>
              {props.apiData.map((singleData, key) => {
                return(
                  <CSSTransition key={key} in={singleData.acf_fc_layout !== 'lassoslide' && currentSlide === key} timeout={{enter: 1470, exit: 700}} classNames="datalink" unmountOnExit={true}>
                      <div className="inner slide"><LinkWrapper><Link className={`${singleData.textcolor}`} to={singleData.link ? getPageLink(singleData.link) : ''}>{props.isMobile ? <ArrowLink className="linkarrow"/> : null}{singleData.linktitle}</Link></LinkWrapper></div>
                  </CSSTransition>
                ) 
              })}
              {props.allLassoNames.map((data, key) => {
                return(
                  <CSSTransition key={key} in={props.apiData[currentSlide].acf_fc_layout == 'lassoslide' && data == props.currentLassoName} timeout={{enter: 1470, exit: 700}} classNames="lassolink" unmountOnExit={true}>
                      <div className="inner lasso"><span className={`lassotitle poster`} dangerouslySetInnerHTML={{__html: data}}/></div>
                  </CSSTransition>
                ) 
              })}
            </div>
            <div className={`counter ${props.apiData[currentSlide].textcolor}`}>
              <div className="active-slide-wrapper">{currentSlide+1}</div>
              <div className="all-slides-count">/{props.apiData.length}</div>
            </div>
          </div>
          <div className={`lassotimer ${props.lassoCarousselIsRunning ? 'running' : ''}`}/>
	    </div>
    </div>
  );
}

export default connect((state, ownProps) => ({
  apiData: state.App.pageData[ownProps.dataKey].data,
  screenSize: state.Device.size,
  isMobile: state.Device.isMobile,
  currentLassoName: state.Lasso.currentLassoName,
  allLassoNames: state.Lasso.allLassoNames,
  lassoCarousselIsRunning: state.Lasso.lassoCarousselIsRunning
}))(Frontpage)
