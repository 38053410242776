import React, {useEffect, useRef} from 'react'
import {Switch, Route, Link, useRouteMatch} from 'react-router-dom'
import {connect} from 'react-redux'
import {getApiBase, isMobile} from './../../helpers/helpers'

const fetchData = async (dispatch, match, archiveQuery) => {
	// console.log(match);
	const siteMap = {
			'/': {
				'api': getApiBase()+'/wp-json/wp/v2/acf/options/frontpage-sections',
				'component': 'Frontpage',
				'menuname': null
			},
			'/projects': {
				'api': getApiBase()+'/wp-json/data/v2/projects',
				'component': 'Projects',
				'menuname': 'projects'
			},
			'/archive': {
				'api': getApiBase()+'/wp-json/data/v2/archive'+archiveQuery,
				'component': 'Archive',
				'menuname': 'projects'
			},
			'/people': {
				'api': getApiBase()+'/wp-json/data/v2/people',
				'component': 'People',
				'menuname': 'people'
			},
			'/studio': {
				'api': getApiBase()+'/wp-json/wp/v2/pages?slug=studio',
				'component': 'Studio',
				'menuname': 'studio'
			},
			'/projects/:id': {
				'api': getApiBase()+'/wp-json/data/v2/singleproject?slug='+match.params.id,
				'component': 'Projectsingle',
				'menuname': 'projects'
			},
			'/contact': {
				'api': getApiBase()+'/wp-json/wp/v2/pages?slug=contact',
				'component': 'Contact',
				'menuname': 'contact'
			}
		}
		
	const response = await fetch(siteMap[match.path].api);
	const jsonResponse = await response.json();

	dispatch({
		type: 'PAGE_HAS_LOADED',
		pageObj: {
			url: match.url,
			component: siteMap[match.path].component,
			menuname: siteMap[match.path].menuname,
			data: jsonResponse
		}
	})
}

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

function ApiFetcher(props) {
	const receiveUrl = props.match.url;
	const prevUrl = usePrevious(receiveUrl);

	useEffect(() => {
		if (receiveUrl !== prevUrl) {
			// console.log('use effect B', props);
			props.dispatch({type: 'PAGE_IS_LOADING'})
			if (isMobile()) props.dispatch({type: 'SET_MENU_IS_SHOWING', val: false});

			// use existing api-data from redux if exists
			// console.log(props.match.url, props.onArchiveAndShouldReload, props.archiveQuery);
				if (props.pageData) {
					if (!props.onArchiveAndShouldReload) {
						if (props.pageData[props.match.url]) {
							props.dispatch({type: 'SET_CURRENT_PAGE', pageKey: props.match.url})
							return;
						}
					}
				}

			// else fetch from api
				fetchData(props.dispatch, props.match, props.archiveQuery)
		}
	}, [receiveUrl])

	return null;
}

export default connect((state, ownProps) => ({
	archiveQuery: state.router.location.search,
	pageData: state.App.pageData,
	onArchiveAndShouldReload: state.Archive.selectedTermsAsArray.length > 0 && ownProps.match.url === '/archive' && state.router.location.search == ''
}))(ApiFetcher)