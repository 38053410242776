import React from 'react';
import './index.scss';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom'

import {generateLink, convertHTMLStringToReact} from './../../helpers/helpers'
import LinkWrapper from './../LinkWrapper'

function ActiveFilterTags(props) {
  return (
    <div className={`active-filter-tags ${props.selectedTermsAsArray.length === 0 ? 'hide' : 'show'}`}>
    	{props.selectedTermsAsArray.map((tax, taxKey) => {
        let currentTax = props.taxonomyAll.find((taxAll) => taxAll.displayname === tax.tax);

        return (
        		tax.terms.map(term  => {
        			let currentTerm = currentTax.terms.find((taxToSearch) => taxToSearch.slug === term);
        			return <LinkWrapper key={Math.random()}><Link className="term" to={`/archive?${generateLink(tax.tax, term, true, props.selectedTerms, props.selectedTermsAsArray)}`}>{convertHTMLStringToReact(currentTerm.name)}</Link></LinkWrapper>
        		}
    		  )
    		)})}
    </div>
  );
}

export default connect((state) => ({
	selectedTerms: state.Archive.selectedTerms,
  selectedTermsAsArray: state.Archive.selectedTermsAsArray,
}))(ActiveFilterTags);
