import React, {useState, useEffect, useRef} from 'react';
import {connect} from 'react-redux'

import './LassoSlide.scss';

import {Lasso} from './Lasso'

function LassoSlideContainer(props) {
	const [lasso, setLasso] = useState(new Lasso(setCurrentLassoName, startCarousselTimer, stopCarousselTimer));
	const resizeRef = useRef();

	function setCurrentLassoName() {
		var currentLassoName = lasso.actions.getCurrentLassoName();
		props.dispatch({
			type: 'SET_CURRENT_LASSO_NAME',
			name: currentLassoName
		})
	}

	function startCarousselTimer(time) {
		// console.log('starting carousseltimer in react', time);

		props.dispatch({
			type: 'LASSO_CAROUSSEL_IS_RUNNING',
			val: true
		})
	}

	function stopCarousselTimer() {
		// console.log('stopping carousseltimer in react');

		props.dispatch({
			type: 'LASSO_CAROUSSEL_IS_RUNNING',
			val: false
		})
	}

	useEffect(() => {
		lasso.init();

		props.dispatch({
			type: 'SET_LASSONAMES',
			lassoNames: lasso.actions.getAllLassoNames()
		})

		return () => {
			lasso.actions.destroy()
		}
	}, [])

	useEffect(() => {
		// console.log('ON LASSOSLIDE: ', props.onLassoSlide);

		if (props.onLassoSlide) lasso.actions.startLassoCarousel();
		else lasso.actions.stopLassoCarousel();

	}, [props.onLassoSlide])

	useEffect(() => {
		// console.log('YES : it works');
	}, [lasso.lassoNames[lasso.currentLassoName]])

	useEffect(() => {
		if (resizeRef.current !== undefined) {
			lasso.actions.destroy();
			lasso.init();
		}
		resizeRef.current = true;
	}, [props.screenSize])

	return (
		<div className="frontpage-slide lasso">
			{/*<p className="text">Rodeo is an interdisciplinary architectural practice founded in 2007 that has established itself in the overlap between design, strategy, and research, with a portfolio of acclaimed national and international projects.</p>*/}
			<canvas id="lasso-canvas" resize="true" keepalive="true"/>
		</div>
		)
}

export default connect((state) => ({
	screenSize: state.Device.size,
	onLassoSlide: state.Lasso.onLassoSlide
}))(LassoSlideContainer)