import React from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom'
import LazyImageComponent from './../LazyImageComponent'
import LinkWrapper from './../LinkWrapper'

function ArchiveList({projects, archiveIsLoading, filterIsAnimating, achiveLayoutIsWide}) {
	return(
		<div className={`page-section archive-list ${archiveIsLoading ? 'loading' : ''} ${!achiveLayoutIsWide ? 'open' : ''}`}>
            <div className={`inner ${filterIsAnimating ? 'hide' : ''}`}>
                {projects.map((singleData, key)  => (
                    <div key={key} className={`col col4 project-thumb col8-s`}>
                        <LinkWrapper>    
                            <Link to={`/projects/${singleData.slug}`}>
                	    		<div className="inner">
                                    <LazyImageComponent width={singleData.thumb.sizes[`project_thumb-width`]} height={singleData.thumb.sizes[`project_thumb-height`]} url={singleData.thumb.sizes.project_thumb}/>
                    				{/*<div className="category">{singleData.category.name}</div>
                    				<div className="year">{singleData.year.name}</div>*/}
                    				<div className="title">{singleData.title}</div>
                	    		</div>
                            </Link>
                        </LinkWrapper>    
        	    	</div>
    	    	))}
                {projects.length === 0 ? <div class="notfound">no projects found</div> : null}
            </div>
	    </div>
		)
}

export default connect((state) => ({
    archiveIsLoading: state.Archive.archiveIsLoading,
    filterIsAnimating: state.Sidebar.filterIsAnimating,
    achiveLayoutIsWide: state.Sidebar.achiveLayoutIsWide
}))(ArchiveList)