import React from 'react';
import './App.scss';
import {connect} from 'react-redux'

import NavigationMenu from './NavigationMenu'
import Brandlogo from './Brandlogo'
import Routes from './Routes';
import Loader from './Loader'
import CurrentMenuName from './CurrentMenuName'
import CurrentSingleName from './CurrentSingleName'
import AnimatedScrollHandler from './AnimatedScrollHandler'
import Cursor from './Cursor'
import {RemoveScrollBar} from 'react-remove-scroll-bar';
 

function App(props) {
	const mouseMove = (e) => {
    props.dispatch({
      type: 'SET_CURSOR_POSITION',
      xPos: e.clientX,
      yPos: e.clientY
    })
    // tjeckSlideDir(e.clientY);
  }

  return (
    <div className="App"
        onMouseMove={(e) => mouseMove(e)} 
        onMouseEnter={() => props.dispatch({type: 'SHOW_CURSOR', show: true})} 
        onMouseLeave={() => props.dispatch({type: 'SHOW_CURSOR', show: false})} 
    >
		{/*<RemoveScrollBar />*/}
      <Cursor/>
      {/*<CurrentMenuName/>*/}
      <CurrentSingleName/>
      <Loader/>
    	<Brandlogo/>
    	<NavigationMenu/>
    	<Routes/>
      <AnimatedScrollHandler/>
    </div>
  );
}

export default connect()(App);
