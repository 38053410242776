import React, {useEffect, useState, useRef} from 'react';
import {Link} from 'react-router-dom'
import {connect} from 'react-redux'
import {Animate} from 'react-move';
import {CSSTransition} from 'react-transition-group';
import { easeExpInOut, easeCubicInOut } from 'd3-ease'

import {getApiBase} from './../../helpers/helpers.js';

import './../../../scss/variables/index.scss';
import './index.scss';

import Footer from './../Footer'
import LinkWrapper from './../LinkWrapper'

function Projects(props) {
	const [currentThumbImage, setCurrentThumbImage] = useState()
	// const [previousThumbImage, setPreviousThumbImage] = useState(0)
	const [thumbTimer, setThumbTimer] = useState();
	const [thumbTimerVal] = useState(4000);
	const [thumbWrapperIsFixed, setThumbWrapperIsFixed] = useState(false);
	const [thumbOffset, setThumbOffset] = useState(null);
	const [imageWidth, setImageWidth] = useState();
	const [allProjectsHover, setAllProjectsHover] = useState(false);

	const thumbImgRef = useRef();
	const thumbWrapperRef = useRef();
	const imageWrapperColumnRef = useRef();

	useEffect(() => {
		preloadThumbImages(props.apiData);
		setCurrentThumbImage(0);
		runThumbTimer(0);
	}, [])

	const setTheThumbOffset = () => {
		var widthToUse = (imageWrapperColumnRef.current.offsetWidth - 20)*0.75/2
		const imgOffset = imageWrapperColumnRef.current.offsetTop + widthToUse;
		setThumbOffset(imgOffset);
	}

	useEffect(() => {
		let newImageWidth = imageWrapperColumnRef.current.offsetWidth - 20;
		setImageWidth(newImageWidth)

		setTimeout(() => {
			if (thumbOffset && props.deviceSize.width > 800) setTheThumbOffset();
		},50)
	}, [props.deviceSize])

	useEffect(() => {
		if (thumbImgRef.current && props.deviceSize.width > 800 && props.currentPageType === 'Projects') {
			if (!thumbOffset) setTheThumbOffset()
			const currentScrollCenter = window.innerHeight/2 + props.scrollPosition;
			if (thumbOffset) {
				let shouldThumbBeFixed = currentScrollCenter > thumbOffset;
				setThumbWrapperIsFixed(shouldThumbBeFixed);
			}


		}
	}, [props.scrollPosition])

	const preloadThumbImages = (apiData) => {
		// console.log('preloading thumbs');
		apiData.map((data) => {
			new Image().src = data.thumb.sizes.project_thumb
		})
	}

	const setActiveThumbImage = (key) => {
		setCurrentThumbImage(key);
	}

	const mouseHoverAllProjects = (val) => {
		if (props.pathname == '/projects') {
			setAllProjectsHover(val);

			if (val) {
				setActiveThumbImage(props.apiData.length - 1)
				clearTimeout(thumbTimer)
			} else {
				setActiveThumbImage(0)
				runThumbTimer(0);
			}
		}
	}

	const mouseOver = (key) => {
		setActiveThumbImage(key);
		clearTimeout(thumbTimer)
	}

	const mouseLeave = () => {
		runThumbTimer(currentThumbImage);
	}

	const getThumbOffset = () => {
		if (thumbImgRef.current) {
			const imgOffset = thumbImgRef.current.offsetTop + thumbImgRef.current.offsetHeight/2;
			const currentCenter = window.innerHeight/2 + props.scrollPosition;
			let thumbOffset = currentCenter >= imgOffset ? currentCenter - imgOffset : 0;
			return thumbOffset
		}
	}

	const runThumbTimer = (thumbId) => {
		clearTimeout(thumbTimer)
		let timer = setTimeout(() => {
			let newId = thumbId + 1 >= props.apiData.length ? 0 : thumbId + 1;
			setActiveThumbImage(newId)
			// gotoNextThumb();
			runThumbTimer(newId);
		}, thumbTimerVal);

		setThumbTimer(timer);
	}

	const isActive = (key, thumbId) => {
		return key == thumbId
	}

  return (
    <div className="page projects no-headline">
    	{/*<div className="more-projects-link more-link-right"><Link to="/archive">ALL PROJECTS</Link></div>*/}
    	<div className="page-section selected-projects-list">
	    	<div className="col col6 colmar1r colmar1l highlighted-project-image colmar2l-s colmar0r-s col14-s" ref={imageWrapperColumnRef}>
	    		<div className="inner">
	    			<div className={`image-wrapper ${thumbWrapperIsFixed ? 'fixed' : ''}`} style={{width: imageWidth, height: imageWidth * 0.75}} ref={thumbWrapperRef}>
	    				 {props.apiData.map((singleData, key) => (
	    					/*<Animate
	    						key={key}
	    						start={{opacity: 0, offset: 0}}
	    						update={{
	    							opacity: isActive(key, currentThumbImage) ? [1] : [0],
	    							offset: isActive(key, currentThumbImage) ? [0] : key >= currentThumbImage ? [5] : [-5],
	    							timing: {duration: isActive(key, currentThumbImage) ? 450 : 400, delay: isActive(key, currentThumbImage) ? 100 : 0, ease: easeCubicInOut}
	    						}}
	    					>
		    					{({opacity, offset}) => (
									<img ref={key === 0 ? thumbImgRef : null} src={singleData.thumb.sizes.project_thumb} style={{transform: `translateY(${offset}px)`, opacity: opacity}}/>
		    					)}
	    					</Animate>*/
	    					<CSSTransition key={key} in={key == currentThumbImage ? true : false} timeout={{enter: 550, exit: 400}} classNames="selected-project-thumb" unmountOnExit={false}>
								<img ref={key === 0 ? thumbImgRef : null} src={singleData.thumb.sizes.project_thumb}/>
		                  	</CSSTransition>
    					))}
	    			</div>
	    		</div>
	    	</div>
	    	<div className="col8 col col16-s projectslistcol">
	    		<ul className="projectsview">
    				<li className={`${!allProjectsHover ? 'selected' : ''}`}><LinkWrapper><Link to="/projects">Selected Projects</Link></LinkWrapper></li>
    				<li onMouseEnter={() => mouseHoverAllProjects(true)} onMouseLeave={() => mouseHoverAllProjects(false)}><LinkWrapper><Link to="/archive">All Projects</Link></LinkWrapper></li>
	    		</ul>
	    		<ul className="projectlist">
	    			{props.apiData.map((singleData, key)  => {
    					return(
    							<li key={key} className={`${key == currentThumbImage ? 'active' : ''}`}><LinkWrapper><Link to={`/projects/${singleData.slug}`} onMouseEnter={() => mouseOver(key)} onMouseLeave={() => mouseLeave()}><span dangerouslySetInnerHTML={{__html: singleData.alternative_title && props.deviceSize.width <= 800 ? singleData.alternative_title : singleData.title}}/></Link></LinkWrapper></li>
    						)
    				})}
	    		</ul>
	    	</div>
	    </div>
    </div>
  );
}

export default connect((state, ownProps) => ({
	deviceSize: state.Device.size,
	scrollPosition: state.App.scrollPosition,
	apiData: state.App.pageData[ownProps.dataKey].data,
	currentPageType: state.App.pagesToShow[0].component,
	pathname: state.router.location.pathname
}))(Projects);