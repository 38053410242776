import React, {useState, useEffect, useRef} from 'react';
import {Link, useLocation} from 'react-router-dom'
import {connect} from 'react-redux'

import './index.scss';

import {fetchArchiveFromFilter, CloseFilter} from './../../actions'
import BottomPageLink from './../BottomPageLink'
import ArchiveList from './ArchiveList'
import ActiveFilterTags from './../ActiveFilterTags'
import Footer from './../Footer'
import LinkWrapper from './../LinkWrapper'

function Archive(props) {
    const hasMountTermsGet = useRef(false)
    const hasMountTermsSet = useRef(false)

    // Close filter if entering by link, keep filter state if entering by browser history
        useEffect(() => {
            if (props.routerAction == 'PUSH') CloseFilter();
        }, [])

    // update redux based on browserbar query
        useEffect(() => {
            let shouldUpdateQuery;

            if (hasMountTermsGet.current) {
                shouldUpdateQuery = true;
            } else {
                hasMountTermsGet.current = true;
                shouldUpdateQuery = false;
            }

            if (props.pathname === '/archive') {
                props.dispatch({
                    type: 'SET_ACTIVE_TAGS',
                    terms: props.query,
                    shouldUpdateQuery: shouldUpdateQuery
                })
            }

        }, [props.query])

    // fetch from API whens selected terms changes in redux
        useEffect(() => {
            if (hasMountTermsSet.current) {
                if (props.selectedTerms && props.shouldUpdateQuery) {
                    fetchArchiveFromFilter(props.selectedTerms, props.dataKey);
                }
            } else {
                hasMountTermsSet.current = true;
            }
        }, [props.selectedTerms])

  return (
    <div className="page project-archive">
        <div className="page-section headline">
            <div className="col col8 title col16-xl">
                <div className="inner">
                    <h1>All Projects</h1>
                </div>
            </div>
            <div className="col col8 data col16-xl col16-med colmar0l-med colmar0r-med">
                <div className="inner">
                    <ActiveFilterTags taxonomyAll={props.apiData.taxonomies}/>
                </div>
            </div>
        </div>

        <ArchiveList projects={props.apiData.posts[0]}/>
        <BottomPageLink data={props.apiData.pagelink_bottom[0]}/>
        <Footer/>
    </div>
  );
}

export default connect((state, ownProps) => ({
    apiData: state.App.pageData[ownProps.dataKey].data,
    selectedTerms: state.Archive.selectedTerms,
    query: state.router.location.query,
    pathname: state.router.location.pathname,
    scrollPosition: state.App.scrollPosition,
    routerAction: state.router.action,
    shouldUpdateQuery: state.Archive.shouldUpdateQuery,
    filterIsOpen: state.Sidebar.filterIsOpen,
}))(Archive);