import React from 'react';
import {connect} from 'react-redux'
import NodeGroup from 'react-move/NodeGroup'

import { easeExpInOut, easeExpIn } from 'd3-ease'
import BezierEasing from 'bezier-easing'

import People from './../People' 
import Projects from './../Projects' 
import ProjectSingle from './../ProjectSingle' 
import Frontpage from './../Frontpage'
import ProjectsArchive from './../ProjectsArchive'
import Studio from './../Studio'
import Contact from './../Contact'

import ArchiveFilter from './../ArchiveFilter';
import Sidebar from './../Sidebar';

function RouteRenderer(props) {
	// console.log(props.pagesToShow);

	var easingIn = BezierEasing(0.9, 0, 0.1, 1);
	var easingInAlt = BezierEasing(0, 0.65, 0.2, 1);
	var easingOut = BezierEasing(0.55, 0, 1, 0.5);

	const onScroll = (e) => {
		if (props.scrollIsActive) {
			props.dispatch({
				type: 'SET_SCROLL_POSITION',
				scrollPosition: e.target.scrollTop
			})
		}
	}

	return(
		<div>
			<NodeGroup
				data={props.pagesToShow}
				keyAccessor={(d) => d.key}
				start={(d) => ({opacity: 0, yPos: 100})}
				enter={() => ([
					{yPos: [0], timing: {delay: 400, duration: 600, ease: easingInAlt}},
					{opacity: [1], timing: {delay: 400, duration: 200}}
					])}
				leave={() => ({
					opacity: [0], 
					yPos: [-30], 
					timing: {duration: 400, ease: easingOut}, 
					// events: {end: () => setTimeout(() => {
					// 	props.dispatch({type: 'PAGE_TRANSITION_COMPLETE'})
					// }, 100)}
				})}
				>
					{(nodes) => (
						<div>
						{nodes.map(({key, data, state}) => (
								<div key={key} className="loadwrapper" style={{opacity: state.opacity, transform: `translateY(${state.yPos}px)`}}>
									<div key={key} className={`scroll-wrapper ${!props.scrollIsActive ? 'disable' : ''}`} onScroll={onScroll}>
										{
											data.component === 'Projects' ? <Projects dataKey={data.key}/> :
											data.component === 'Frontpage' ? <Frontpage dataKey={data.key}/> :
											data.component === 'People' ? <People dataKey={data.key}/> : 
											data.component === 'Studio' ? <Studio dataKey={data.key}/> : 
											data.component === 'Archive' ? <ProjectsArchive dataKey={data.key}/> : 
											data.component === 'Projectsingle' ? <ProjectSingle dataKey={data.key}/> :
											data.component === 'Contact' ? <Contact dataKey={data.key}/> : null	
										}
									</div>
										{
										data.component === 'Archive' ? <ArchiveFilter dataKey={data.key}/> : 
										data.component === 'Projectsingle' ? <Sidebar dataKey={data.key}/> : null
									}
								</div>
							))}
							</div>
						)
					}
			</NodeGroup>
		</div>
		)
}

export default connect((state) => ({
	pagesToShow: state.App.pagesToShow,
	scrollIsActive: state.App.scrollIsActive
}))(RouteRenderer)
