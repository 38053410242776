import React from 'react';
import './index.scss';
import {Animate} from 'react-move'
import {connect} from 'react-redux'

import {ReactComponent as IconInformation} from './../../../assets/icons/IconInformation.svg'
import {Text, Image, Button} from './sidebarSections.js'
import {ToggleSidebar} from './../../actions'
import LinkWrapper from './../LinkWrapper'

function Sidebar(props) {
  return (
    <div>
      <LinkWrapper><div className={`toggle-sidebar-btn${props.sidebarIsOpen ? ' open' : ''}`} onClick={() => ToggleSidebar()}><div className="inner"><div className="open"><IconInformation/></div><div className="close"/></div></div></LinkWrapper>

      <div className={`sidebar ${props.sidebarIsOpen ? 'open' : 'closed'}`} style={{top: props.scrollPosition > 100000 ? 1323 - props.scrollPosition : 0}}>
        <div className="sidebar-sections">
          <h1 className="headline">info</h1>
          {props.apiData.more_info_sections ? props.apiData.more_info_sections.map((singleData, key) => (
              singleData.acf_fc_layout === 'image' ? <Image key={key} data={singleData}/> : 
              singleData.acf_fc_layout === 'text' ? <Text key={key} data={singleData}/> :
              singleData.acf_fc_layout === 'download_button' ? <Button key={key} data={singleData}/> : null
          )) : null}
        </div>
      </div>
    </div>
  );
}

export default connect((state, ownProps) => {return({
  apiData: state.App.pageData[ownProps.dataKey].data,
	sidebarIsOpen: state.Sidebar.sidebarIsOpen,
  scrollPosition: state.App.scrollPosition
})})(Sidebar);
