import React from 'react';
import LazyImageComponent from './../LazyImageComponent'
import ScrollVisibilityComponent from './../ScrollVisibilityComponent'

export const ImageLarge = ({data}) => (
	<div className="page-section project-section">
        <div className="col col14 colmar1l colmar1r col16-xl colmar0r-xl colmar0l-xl">
            <ScrollVisibilityComponent>
                <LazyImageComponent width={data.image.sizes[`fullscreen-width`]} height={data.image.sizes[`fullscreen-height`]} url={data.image.sizes.fullscreen}/>
                <div className="caption">{data.caption}</div>
            </ScrollVisibilityComponent>
        </div>
    </div>
    )

export const ImageFullscreen = ({data}) => (
	<div className="page-section project-section full">
        <ScrollVisibilityComponent>
            <LazyImageComponent width={data.image.sizes[`fullscreen-width`]} height={data.image.sizes[`fullscreen-height`]} url={data.image.sizes.fullscreen}/>
            <div className="caption">{data.caption}</div>
        </ScrollVisibilityComponent>
    </div>
    )

export const Video = ({data, vid}) => {
    return(
        <div className="page-section project-section video">
            <div className="col col14 colmar1l colmar1r col16-xl colmar0r-xl colmar0l-xl">
                <ScrollVisibilityComponent callback={data.videoservice == 'vimeo' ? 'video' : null} vidId={vid}>
                    {
                        data.videoservice == 'vimeo' ? <iframe id={vid} src={`https://player.vimeo.com/video/${data.vimeo_id}?autoplay=1&loop=1&autopause=1&muted=1`} width="640" height="360" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe> :
                        data.videoservice == 'youtube' ? <iframe id={vid} width="560" height="315" src={`https://www.youtube.com/embed/${data.youtube_id}?&autoplay=1&loop=1`} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe> : null
                    }
                </ScrollVisibilityComponent>
            </div>
        </div>
    )}

export const Text = ({data}) => (
	<div className="page-section project-section"> 
        <div className="col col14 colmar1l colmar1r quote">
            <ScrollVisibilityComponent>
                <div className="inner">{data.text}</div>
                <div className="caption">{data.caption}</div>
            </ScrollVisibilityComponent>
        </div>
    </div>
    )

export const TwoImages = ({data}) => (
	<div className="page-section project-section">
        <div className={`${data.layout == 'bigleft' ? 'col8 col9-xl colmar0l-xl' : 'col6 col7-xl colmar0l-xl'} col colmar1l bottom`}>
            <div className="inner">
                <ScrollVisibilityComponent>
                    <LazyImageComponent width={data.image_left.sizes[`halfscreen-width`]} height={data.image_left.sizes[`halfscreen-height`]} url={data.image_left.sizes.halfscreen}/>
                    <div className="caption left">{data.caption_image_left}</div>
                </ScrollVisibilityComponent>
            </div>
        </div>
        <div className={`${data.layout == 'bigright' ? 'col8 col9-xl colmar0r-xl' : 'col6 col7-xl colmar0r-xl'} col colmar1r bottom`}>
            <div className="inner">
                <ScrollVisibilityComponent>
                    <LazyImageComponent width={data.image_right.sizes[`halfscreen-width`]} height={data.image_right.sizes[`halfscreen-height`]} url={data.image_right.sizes.halfscreen}/>
                    <div className="caption right">{data.caption_image_right}</div>
                </ScrollVisibilityComponent>
            </div>
        </div>
    </div>
	)

export const ImageAndText = ({data}) => (
    <div className="page-section project-section">
        <div className="col6 col7-xl colmar0l-xl col colmar1l col16-s smallquote">
            <div className="inner">
                <ScrollVisibilityComponent>
                    <div className="text">{data.text}</div>
                </ScrollVisibilityComponent>
            </div>
        </div>
        <div className="col8 col9-xl colmar0r-xl col colmar1r bottom col16-s">
            <div className="inner">
                <ScrollVisibilityComponent>
                    <LazyImageComponent width={data.image.sizes[`halfscreen-width`]} height={data.image.sizes[`halfscreen-height`]} url={data.image.sizes.halfscreen}/>
                    <div className="caption right">{data.caption_image}</div>
                </ScrollVisibilityComponent>
            </div>
        </div>
    </div>
    )