import {store} from './../config/configureStore.js'
import parse, {domToReact} from 'html-react-parser'
import LinkWrapper from './../components/LinkWrapper'
import React from 'react'
import Player from '@vimeo/player'

export function getApiBase() {
	return process.env.NODE_ENV === 'production' ? 'https://rodeo-arkitekter.no/api' : 
	// 'http://dev.rodeo-backend';
'https://rodeo-arkitekter.no/api';
	// 'http://rodeo.troelsknud.dk/api';
}

const mobileBreakpoint = 1200;

export const isMobile = () => {
  return store.getState().Device.size.width <= mobileBreakpoint ? true : false;
}

export const generateLink = (tax, term, isSelected, selectedTerms, selectedTermsAsArray) => {
    let query = '';
    let taxAllreadyExists = false;

    let i = 0;
    selectedTermsAsArray.forEach((ele) => {
      let queryAppend = i != 0 ? '&' : '';

      if (ele.tax == tax) {
        taxAllreadyExists = true;

        if (isSelected) {
          let newTermsForTax = ele.terms.filter(currentTerm => currentTerm !== term);
          query += newTermsForTax.length > 0 ? queryAppend + ele.tax + '=' + newTermsForTax : ''
        } else {
          query += queryAppend + ele.tax + '=' + selectedTerms[ele.tax] + ',' + term;
        }
      } else {
        query += queryAppend + ele.tax + '=' + selectedTerms[ele.tax];
      }

      i++;
    })

    if (!taxAllreadyExists) {
      query += selectedTermsAsArray.length > 0 ? '&' : '';
      query += tax + '=' + term;
    }

    return query;
  }

export const getPageLink = (pageObj) => {
        let prefix;

        switch(pageObj.post_type) {
            case 'projects':
                prefix = '/projects';
            break;

            case 'people':
                prefix = '/people';
            break;

            case 'page':
                prefix = '';
            break;
        }

        const slug = pageObj.post_type == 'page' && pageObj.post_name == 'frontpage' ? '/' : '/'+pageObj.post_name;
    return prefix + slug
  }

export const convertHTMLStringToReact = (htmlString) => {
    const options = {
      replace: ({attribs, children}) => {
        if (!attribs) return;

        if (attribs.href) {
          return <LinkWrapper><a href={attribs.href.toLowerCase()} target={attribs.target}>{domToReact(children, options)}</a></LinkWrapper>
        }
      }
    }

   return parse(htmlString, options)
}

export const playPauseVideo = (state, id) => {
    var iFrame = document.getElementById(id);
    var player = new Player(iFrame);
    if (state) player.play();
    else player.pause()
  }