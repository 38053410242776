import React, {Component} from 'react';
import {Route, Switch} from 'react-router-dom';

import './index.scss';

import RouteRenderer from './RouteRenderer.js'
import ApiFetcher from './ApiFetcher.js'

function Routes() {
		return (
			<div>
				<Route path="/projects/:id" component={ApiFetcher}/>
				<Route exact path="/" component={ApiFetcher}/>
				<Route exact path="/projects" component={ApiFetcher}/>
				<Route path="/studio" component={ApiFetcher}/>
				<Route path="/archive" component={ApiFetcher}/>
				<Route path="/people" component={ApiFetcher}/>
				<Route path="/contact" component={ApiFetcher}/>

				<RouteRenderer/>
			</div>
		);
	}



export default Routes
