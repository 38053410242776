import {store} from './../config/configureStore.js'
import {getApiBase} from './../helpers/helpers.js';

export const ToggleFilter = () => {
	const filterIsOpen = store.getState().Sidebar.filterIsOpen;

	const changeLayout = (toWideLayout) => {
		store.dispatch({type: 'FILTER_IS_ANIMATING', val: true})
		setTimeout(() => {
			store.dispatch({type: 'SET_ARCHIVE_LAYOUT_TO_WIDE', val: toWideLayout})
			store.dispatch({type: 'FILTER_IS_ANIMATING', val: false})
		}, 150)
	}

	store.dispatch({type: 'TOGGLE_FILTER'})

    moveBrandlogoBack(filterIsOpen ? false : true);

	const dly = filterIsOpen ? 200 : 500

    setTimeout(() => {
		changeLayout(filterIsOpen ? true : false);
    }, dly)
}

export const CloseFilter = () => {
    store.dispatch({type: 'CLOSE_FILTER'});
    moveBrandlogoBack(false)
}

export const ToggleSidebar = () => {
    const sidebarIsOpen = store.getState().Sidebar.sidebarIsOpen;
    store.dispatch({type: 'TOGGLE_SIDEBAR'})
    moveBrandlogoBack(sidebarIsOpen ? false : true)
}

export const CloseSidebar = () => {
    store.dispatch({type: 'CLOSE_SIDEBAR'});
    moveBrandlogoBack(false);
}

export const fetchArchiveFromFilter = async (terms, dataKey) => {
    // hide archive posts
        store.dispatch({
            type: 'ARCHIVE_IS_LOADING',
            val: true
        })
        
    // fetch posts
        let query = getApiBase()+'/wp-json/data/v2/archive?';
        query += terms.year ? 'year='+terms.year+'&' : '';
        query += terms.audience ? 'audience='+terms.audience+'&' : '';
        query += terms.context ? 'context='+terms.context+'&' : '';
        query += terms.category ? 'category='+terms.category : '';


        const response = await fetch(query);
        const jsonResponse = await response.json();

    // store posts in redux
        store.dispatch({
            type: 'UPDATE_ARCHIVE_POSTS',
            posts: jsonResponse.posts,
            dataKey: dataKey
        })

    // scroll to top
        const scrollWrapper = document.getElementsByClassName('scroll-wrapper')[0];
        if (scrollWrapper) scrollWrapper.scrollTop = 0;


    // show archive posts
        store.dispatch({
            type: 'ARCHIVE_IS_LOADING',
            val: false
        })
}

export const moveBrandlogoBack = (val) => {
    setTimeout(() => {
        store.dispatch({type: 'MOVE_BRANDLOGO_BACK', val: val})
    }, val ? 0 : 500)
}

export const setDeviceToMobile = (isMobile) => ({
    type: 'SET_DEVICE_TO_MOBILE',
    isMobile: isMobile
})

export const setDeviceSize = (width, height) => ({
    type: 'SET_DEVICE_SIZE',
    width: width,
    height: height
})

export const animateScroll = (scrollDest) => {
    store.dispatch({type: 'RESET_ANIMATED_SCROLL'})

    setTimeout(() => {
        store.dispatch({type: 'SET_SCROLL_DEST', scrollDest: scrollDest})
    }, 10)
}