import React from 'react';
import {Link} from 'react-router-dom'
import LazyImageComponent from './../LazyImageComponent'
import ScrollVisibilityComponent from './../ScrollVisibilityComponent'
import LinkWrapper from './../LinkWrapper'

import './relatedProjects.scss'

export const RelatedProjects = ({data}) => (
        <ScrollVisibilityComponent>
    		<div className="page-section project-section related-projects border-top">
            <h3 className="section-title">Related projects</h3>

    		{data ? data.map((ele, key) => (
        		<div key={key} className="col col33 project-thumb col8-med">
                    <LinkWrapper>
                        <Link to={`/projects/${ele.slug}`}>
                            <div className="inner">
                                <LazyImageComponent width={ele.thumb.sizes[`project_thumb_related-width`]} height={ele.thumb.sizes[`project_thumb_related-height`]} url={ele.thumb.sizes.project_thumb_related}/>
                                {/*<div className="category">{ele.category}</div>
                                <div className="year">{ele.year}</div>*/}
                                <div className="title">{ele.title}</div>
                            </div>
                        </Link>
                    </LinkWrapper>
                </div>
    			)) : null}
        	</div>
        </ScrollVisibilityComponent>
	)