import React from 'react';
import {connect} from 'react-redux'
import NodeGroup from 'react-move/NodeGroup'
import BezierEasing from 'bezier-easing'

import './index.scss';

function CurrentMenuName(props) {
	var easingInAlt = BezierEasing(0, 0.65, 0.2, 1);
	var easingOut = BezierEasing(0.55, 0, 1, 0.5);


  return (
  	<NodeGroup
  		data={props.currentMenuName}
  		keyAccessor={(d) => d}
  		start={() => ({opacity: 0, offset: 6})}
  		enter={() => ({opacity: [1], offset: [0], timing: {duration: 400, delay: 100, ease: easingOut}})}
  		leave={() => ({opacity: [0], offset: [-6], timing: {duration: 400, delay: 0, ease: easingOut}})}
  	>
  	{(nodes) => (
  		<div>
  		{nodes.map(({key, data, state}) => (
    			<div key={key} className={`currentMenuName ${props.menuIsShowing ? 'hide' : ''}`} style={{opacity: state.opacity, transform: `translateY(${state.offset}px)`}} >{data}</div>
  			))}
  		</div>
  		)}
  	</NodeGroup>
  );
}

export default connect(state => ({
	currentMenuName: state.App.currentMenuName,
	menuIsShowing: state.App.menuIsShowing
}))(CurrentMenuName);