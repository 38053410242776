import {combineReducers} from 'redux'
import {connectRouter} from 'connected-react-router'

import App from './App.js' 
// import Scroll from './Scroll.js' 
import Sidebar from './Sidebar.js' 
import Archive from './Archive.js' 
import Brandlogo from './Brandlogo.js' 
import Device from './Device.js'
import Cursor from './Cursor.js'
import Lasso from './Lasso.js'

export default (history) => combineReducers({
	router: connectRouter(history),
	// Scroll: Scroll,
	Sidebar: Sidebar,
	Archive: Archive,
	App: App,
	Brandlogo: Brandlogo,
	Device: Device,
	Cursor: Cursor,
	Lasso: Lasso
})