import React, {useRef, useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {ReactComponent as ArrowIcon} from './../../../assets/icons/arrowLink.svg'
import Snap from 'snapsvg-cjs'
import BezierEasing from 'bezier-easing'
import {store} from './../../config/configureStore.js'
import {getApiBase} from './../../helpers/helpers'

import LazyImageComponent from './../LazyImageComponent'
import LassoSlideContainer from './../LassoSlide/LassoSlide'

export const FullScreenSlide = (props) => {
	return(
		<div className="frontpage-slide full" style={{backgroundImage: `url(${props.img1.sizes.fullscreen})`}}>
            <div className="data bright">
              <h2 className="title">{props.title}</h2>
              <div className="link"><Link to="/projects"><ArrowIcon className="arrow"/>project</Link></div>
            </div>
          </div>
		)
}

export const TwoColSlide = (props) => {
  const image1type = props.img1.sizes[`fullscreen-height`] > props.img1.sizes[`fullscreen-width`] ? 'frontpage-portrait' : 'frontpage-landscape'
  const image2type = props.img2.sizes[`fullscreen-height`] > props.img2.sizes[`fullscreen-width`] ? 'frontpage-portrait' : 'frontpage-landscape'

	return(
  		<div className="frontpage-slide 2col">
        <div className="page-section">
          <div className={`col col6 colmar1l colmar1r colmar0r-mobxl colmar0l-mobxl col8-mobxl ${image1type}`}>
            <div className="inner">
             <LazyImageComponent width={props.img1.sizes[`fullscreen-width`]} height={props.img1.sizes[`fullscreen-height`]} url={props.img1.sizes.fullscreen}/>
            </div>
          </div>
          <div className={`col col5 colmar2l colmar1r colmar0r-mobxl colmar0l-mobxl col8-mobxl ${image2type}`}>
            <div className="inner">
              <LazyImageComponent width={props.img2.sizes[`fullscreen-width`]} height={props.img2.sizes[`fullscreen-height`]} url={props.img2.sizes.fullscreen}/>
            </div>
          </div>
        </div>
      </div>          
		)
}

export const VideoSlide = (props) => (
  <div className="frontpage-slide video">
    <div className="page-section">
      <div className="col col10 colmar3l colmar3r col14-mobxl colmar1l-mobxl colmar1r-mobxl">
        <div className="inner lazy-loading">
            {
                props.videoservice == 'vimeo' ? <iframe id={props.vid} src={`https://player.vimeo.com/video/${props.videoId}?autoplay=1&loop=1&autopause=0&muted=1&${store.getState().Device.isMobile ? 'controls=false' : ''}`} width="640" height="360" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe> :
                props.videoservice == 'youtube' ? <iframe width="560" height="315" src={`https://www.youtube.com/embed/${props.videoId}?&autoplay=1&loop=1`} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe> : null
            }
        </div>
      </div>
    </div>
  </div>
  )

function Thumb(props) {
    const [isLoaded, setIsLoaded] = useState(false);
    const videoRef = useRef();

    const videoCanPlay = () => {
      setIsLoaded(true);
      if (!store.getState().Device.isMobile) videoRef.current.play();
    }

    return(
        <div className="lazy-image-component" style={{paddingBottom: '141.667%'}}>
          {props.videoUrl ? 
            <video ref={videoRef} className={`people-video ${isLoaded ? 'loaded' : 'loading'}`} muted loop onCanPlay={() => videoCanPlay()}>
              <source src={`${props.videoUrl}#t=0.1`} type="video/mp4"/>
          </video>
          : null}
        </div>

    )
  }

export function PeopleSlide() {
  const [data, setData] = useState(null); 

  useEffect(() => {
    const loadPeopleFromAPI = async () => {
      const response = await fetch(getApiBase()+'/wp-json/data/v2/people');
      const jsonResponse = await response.json();
      let peopleNum = [], selectedPeople = [];
      for (let i = 0; i < 3; i++) {
        let numIsUnique = false, num;
        while(!numIsUnique) {
          num = Math.floor(Math.random() * jsonResponse.people.length);
          numIsUnique = !peopleNum.includes(num);
        }
          peopleNum.push(num);
          selectedPeople.push(jsonResponse.people[num])
      }

      setData(selectedPeople);
    }

    loadPeopleFromAPI();
  }, [])

  return(
      <div className="frontpage-slide people">
        <div className="page-section">
          <div className="col col4 colmar1l col8-mobxl hide-mobxl">
            <div className="inner">
              <Thumb videoUrl={data ? data[0].video : null}/>
            </div>
          </div>
          <div className="col col3 colmar2r colmar0r-mobxl colmar0l-mobxl colmar2r-mobxl col6-mobxl">
            <div className="inner">
              <Thumb videoUrl={data ? data[1].video : null}/>
            </div>
          </div>
          <div className="col col5 colmar1r colmar0r-mobxl colmar0l-mobxl col8-mobxl">
            <div className="inner">
              <Thumb videoUrl={data ? data[2].video : null}/>
            </div>
          </div>
        </div>
      </div>          
    )
}

export const CenterSlide = (props) => {
  let horizontalImage = props.img1.sizes[`fullscreen-width`] > props.img1.sizes[`fullscreen-height`] ? true : false;
  let verticalImageWidth = '';
  if (!horizontalImage) {
    const ratio = props.img1.sizes[`fullscreen-width`] / props.img1.sizes[`fullscreen-height`];
    const actualPixelWidth = props.screenSize.height * 0.8 * ratio;
    verticalImageWidth = actualPixelWidth / props.screenSize.width * 100;
  }

	return(
		<div className="frontpage-slide center">
      <div className="page-section">
        <div className="col col10 colmar3l colmar3r col14-mobxl colmar1l-mobxl colmar1r-mobxl">
          <div className="inner">
             <LazyImageComponent width={props.img1.sizes[`fullscreen-width`]} height={props.img1.sizes[`fullscreen-height`]} url={props.img1.sizes.fullscreen}/>
          </div>
        </div>
      </div>
    </div>        
		)
}

export function LassoSlide() {
  return <LassoSlideContainer/>
}

export function LassoSlideOld(props) {
  var easingIn = BezierEasing(0.9, 0, 0.1, 1);
  var easingInAlt = BezierEasing(0, 0.65, 0.2, 1);
  var easingOut = BezierEasing(0.55, 0, 1, 0.5);



  const lassoSVG = useRef();

  useEffect(() => {
    var svg = lassoSVG;
    var s = Snap(svg);

    var lassoRodeo = Snap.select('#lasso-rodeo');
    var lassoSocialScience = Snap.select('#lasso-social-science');
    var lassoArchitecture = Snap.select('#lasso-architectur');
    var lassoStudio = Snap.select('#lasso-studio');

    var lassoRodeoPoints = lassoRodeo.node.getAttribute('d');
    var lassoSocialSciencePoints = lassoSocialScience.node.getAttribute('d');
    var lassoArchitecturePoints = lassoArchitecture.node.getAttribute('d');
    var lassoStudioPoints = lassoStudio.node.getAttribute('d');

    var toLassoRodeo = function(){
      setTimeout(() => {
        lassoRodeo.animate({ d: lassoRodeoPoints }, 600, easingIn, toLassoStudio);  
      }, 2000)
    }

    var toLassoSocialScience = function(){
      setTimeout(() => {
        lassoRodeo.animate({ d: lassoSocialSciencePoints }, 600, easingIn, toLassoRodeo); 
      }, 2000)
    }

    var toLassoArchitectur = function(){
      setTimeout(() => {
        lassoRodeo.animate({ d: lassoArchitecturePoints }, 600, easingIn, toLassoSocialScience); 
      }, 2000)
    }

    var toLassoStudio = function(){
      setTimeout(() => {
        lassoRodeo.animate({ d: lassoStudioPoints }, 600, easingIn, toLassoArchitectur); 
      }, 2000)
    }

    toLassoStudio();

  }, [])

  return(
      <div className="frontpage-slide lasso">
      {
          <svg id="lasso" width="3000" height="3000" viewBox="0 0 3000 3000" xmlns="http://www.w3.org/2000/svg" ref={lassoSVG}>
            <path id="lasso-rodeo" d="M731.52 1022.9C512.51 1030.54 292.06 935.64 147.1 771.3C71.39 685.47 14.26 574.21 26.53 460.43C44.35 295.08 204.56 175.97 367.41 142.26C608.7 92.3 855.14 183.28 1080.58 282.75C1174.89 324.36 1268.54 367.76 1365.08 404.08C1440.42 432.42 1518.24 474.92 1598.67 480.76C1780.57 493.97 1970.12 419.78 2082 272.86C2114.04 230.78 2145.16 161.68 2121.85 108.97C2118.85 102.18 2114.94 95.67 2110.02 89.51C2061.39 28.73 1969.74 18.66 1897.62 26.22C1807.53 35.67 1723.44 81.98 1658.31 143.56C1574.4 222.89 1517.52 326.15 1466.2 429.59C1414.88 533.03 1367.15 639.45 1296.98 731.16C1177.62 887.18 993.86 991.77 799.32 1017.27L731.52 1022.9ZM1289.96 374.84C1141.19 350.05 975.06 351.43 856.13 444.2C794.17 492.53 751.02 561.79 722.89 635.17C655.92 809.81 670.9 1009.94 743.63 1182.26C816.36 1354.58 943.33 1500.38 1091.21 1614.89C1239.1 1729.39 1407.76 1814.31 1580.09 1887" fillRule="nonzero" fill="none" stroke="#000" strokeWidth="10"/>
            {/*<path id="lasso-rodeo" d="M 660 816 C 366 694 348 504 378 421 C 419 280 567 315 615 341 C 729 401 884 279 830 224 C 789 190 705 210 661 277 C 582 395 593 474 437 529 C 313 551 216 499 166 413 C 122 301 230 247 279 246 C 384 232 464 276 615 340 " fillRule="nonzero" fill="none" stroke="#000" strokeWidth="10"/>*/}
            <path opacity="0" id="lasso-social-science" d="M 60 388 C 145 448 259 389 263 278 C 391 374 540 364 654 251 C 700 403 825 442 935 403 C 797 457 817 573 889 614 C 777 590 651 646 698 801 C 654 700 494 616 412 748 C 441 575 264 498 150 611 C 189 569 194 497 150 447 " fillRule="nonzero" stroke="#000" strokeWidth="10" fill="none"/>
            <path opacity="0" id="lasso-architectur" d="M1216.26 1502.59C1385.36 1238.14 1479.56 926.37 1485.19 612.53C1487.73 470.97 1470.73 323.47 1395.52 203.51C1320.32 83.55 1174.85 -0.92 1036.87 30.8C926.78 56.11 841.88 149.51 799.7 254.3C719.98 452.35 760.02 675.74 849.09 864.06C964.41 1107.87 1197.36 1288.38 1457.98 1357.78C1718.6 1427.19 2002.73 1389.49 2245.49 1271.98C2488.25 1154.47 2690.23 960.48 2835 732.91C2620.81 570.34 2125.2 208.91 2125.2 208.91C2125.2 208.91 1613 409.88 1386.38 503.38C932.49 690.63 478.59 877.87 24.7 1065.12C32.34 1190.54 28.2 1303.74 35.84 1429.15" />
            <path opacity="0" id="lasso-studio" d="M24.07 1338.36C171.46 1292.47 362.43 1212.65 499.14 1106.5C609.86 1020.53 810.02 778.55 691.86 683.35C639.53 641.19 534.36 685.57 462.18 779.88C404.9 854.72 378.07 949.32 367.22 1036.99C357.88 1112.42 359.31 1188.38 386.73 1245.84C450.61 1379.67 599.31 1372.26 752.01 1319.04C1055.44 1213.28 1520.36 953.08 1594.92 537.02C1616.9 414.31 1545.19 324.7 1460.42 314.24C1290.6 293.3 1113.07 452.36 995.96 647.65C878.84 842.94 865.01 1089.91 963.35 1230.07C1039.34 1338.37 1223.9 1375.77 1362.78 1360.75C1501.66 1345.73 1646.38 1277.87 1782.04 1188.56C1985.03 1054.92 2174.44 869.64 2318.28 649.3C2376.84 559.59 2428.54 462.37 2453.62 362.18C2478.7 262 2474.3 158.22 2427.31 92.97C2358.98 -1.89 2212.29 6.73 2092.63 87.44C1972.96 168.15 1879.39 306.48 1816.62 447.99C1775.36 541.03 1744.91 638.95 1738.47 732.67C1732 826.92 1749.95 913.39 1775.96 992.63C1808.11 1090.58 1853.99 1181.39 1925.81 1239.36C2068.11 1354.22 2294.02 1321.17 2492.47 1208.95C2690.93 1096.74 2867.66 916.01 3033.93 731.9" />
            {/*<path id="lasso-rodeo" d="M24.92 458.6C146.57 545.82 319.48 554.72 449.44 480.45C579.4 406.17 659.51 252.68 646.11 103.59C812.53 254.23 1041.2 333.88 1265.19 319.23C1489.19 304.59 1705.54 195.85 1850.93 24.82C1882.83 212.1 2004.42 382.04 2171.38 472.67C2338.34 563.31 2547.08 572.71 2721.51 497.44C2622.07 533.88 2527.09 590.75 2464.19 675.96C2401.3 761.17 2375.21 877.84 2414.93 976.02C2442.72 1044.71 2494.28 1106.61 2557.93 1141.97C2398.27 1132.84 2235.54 1113.96 2101.72 1220.83C1958.22 1335.44 1909.96 1553.98 1991.86 1718.35C1901.01 1555.87 1758.39 1412.6 1578.03 1366.52C1397.67 1320.43 1180.84 1395.58 1103.53 1564.93C1169.65 1361.46 1069.03 1117.3 878.76 1019.49C688.49 921.68 431.38 981.95 304.39 1154.13C380.92 1065.73 415.77 942.71 396.98 827.31C384.69 751.84 349.6 680.55 298.65 623.6" fillRule="nonzero" fill="none" stroke="#000" strokeWidth="10"/>
            <path opacity="0" id="lasso-social-science" d="M731.52 1022.9C512.51 1030.54 292.06 935.64 147.1 771.3C71.39 685.47 14.26 574.21 26.53 460.43C44.35 295.08 204.56 175.97 367.41 142.26C608.7 92.3 855.14 183.28 1080.58 282.75C1174.89 324.36 1268.54 367.76 1365.08 404.08C1440.42 432.42 1518.24 474.92 1598.67 480.76C1780.57 493.97 1970.12 419.78 2082 272.86C2114.04 230.78 2145.16 161.68 2121.85 108.97C2118.85 102.18 2114.94 95.67 2110.02 89.51C2061.39 28.73 1969.74 18.66 1897.62 26.22C1807.53 35.67 1723.44 81.98 1658.31 143.56C1574.4 222.89 1517.52 326.15 1466.2 429.59C1414.88 533.03 1367.15 639.45 1296.98 731.16C1177.62 887.18 993.86 991.77 799.32 1017.27L731.52 1022.9ZM1289.96 374.84C1141.19 350.05 975.06 351.43 856.13 444.2C794.17 492.53 751.02 561.79 722.89 635.17C655.92 809.81 670.9 1009.94 743.63 1182.26C816.36 1354.58 943.33 1500.38 1091.21 1614.89C1239.1 1729.39 1407.76 1814.31 1580.09 1887" fillRule="nonzero" stroke="#000" strokeWidth="10" fill="none"/>
            */}
          </svg>
          }

      </div>
    )
}
