import React from 'react';
import {Link} from 'react-router-dom';
import './index.scss';

import {getPageLink} from './../../helpers/helpers.js'
import LazyImageComponent from './../LazyImageComponent'
import ScrollVisibilityComponent from './../ScrollVisibilityComponent'
import LinkWrapper from './../LinkWrapper'

function BottomPageLink(props) {
  return (
    <ScrollVisibilityComponent>
        <div className="page-section bottom-page-link border-top">
    		<h3 className="section-title">{props.data.title}</h3>
    		<div className="row">
        		<div className="col col8 colmar8l col16-s colmar0l-s">
        			<div className="inner">
        				<div className="fade-images">
        					{props.data.image1 ? <LazyImageComponent width={props.data.image1.sizes[`related-width`]} height={props.data.image1.sizes[`related-height`]} url={props.data.image1.sizes.related} className=""/> : null}
        					{props.data.image2 ? <LazyImageComponent width={props.data.image2.sizes[`related-width`]} height={props.data.image2.sizes[`related-height`]} url={props.data.image2.sizes.related} className=""/> : null}
        				</div>
        				<p className="text">{props.data.text}</p>
        				<LinkWrapper><Link className="link" to={getPageLink(props.data.linkObj)}>{props.data.linktext}</Link></LinkWrapper>
        			</div>
        		</div>
    		</div>
    	</div>
    </ScrollVisibilityComponent>
  );
}

export default BottomPageLink