import React, {useState} from 'react';
import VisibilitySensor from 'react-visibility-sensor'
import './index.scss';

// import {LazyLoadImage} from 'react-lazy-load-image-component';

function LazyImageComponent({height, width, url, style, type}) {
	const [isLoaded, setIsLoaded] = useState(false);

	const widthRatio = width/height
	const heightRatio = height/width;

	const styles = {
		paddingBottom: heightRatio * 100+'%',
		// width: '100%',
		// paddingBottom: type === 'frontpage-portrait' ? 0 : heightRatio * 100+'%',
		// width: type === 'frontpage-portrait' ? 'calc((100vh - 24vw) * ' + widthRatio + ')' : '100%',
	}
	
  return (
    	<div className={`lazy-image-component ${isLoaded ? 'loaded' : 'lazyloading'}`} style={styles}><img src={url} onLoad={() => setIsLoaded(true)}/></div>
  );
}

export default LazyImageComponent;
